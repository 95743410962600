import _ from 'lodash'
import ActivityListService from '@serv/ActivityListService'
import ClinicalMilestone from '@model/ClinicalMilestone'
import ClinicalMilestoneService from '@serv/ClinicalMilestoneService'
import ConfigManager from '@config/ConfigManager'
import InfoStep from '@model/content/InfoStep'
import Locale from '@serv/Locale'
import Logging from '@serv/Logging'
import Milestone from '@model/Milestone'
import MilestoneService from '@serv/MilestoneService'
import moment from 'moment'
import { nanoid } from 'nanoid/non-secure'
import NotifyService from '@serv/NotifyService'
import PatientService from '@serv/PatientService'
import Request from '@serv/Request'
import ResourceService from '@serv/ResourceService'
import Schedule from '@model/Schedule'
import Step from '@model/content/Step'
import StepResult from '@model/StepResult'
import store from '@/store'
import Survey from '@model/content/Survey'
import SurveyResult from '@model/SurveyResult'
import SurveyResultsChartSeries from '@model/SurveyResultsChartSeries'
import SurveyService from '@serv/SurveyService'
import User from '@model/User'
import Utils from '@serv/Utils'

/**
 * Functions for slicing and dicing survey results.
 */
class SurveyResultsService {
    constructor() {
        // These are set through a commit to setPracticeSurveyResults
        this.practiceSurveyResults = {}
    }

    // Get all practiceSurveyResults, as a single array
    get practiceSurveyResultsArray() {
        const resultsArrayValues = Object.values(this.practiceSurveyResults || {})
        const allResults = resultsArrayValues.reduce((total, array) => [...total, ...array], [])

        return allResults
    }

    // Initial set of all results for all patients. Pass in an array, which becomes a map by patientId.
    setPracticeSurveyResults(surveyResults) {
        const patientIdToResults = _.groupBy(surveyResults, 'patientId')
        this.practiceSurveyResults = patientIdToResults
        store.commit('setPracticeSurveyResults', true)
    }

    // Add a single patient result.
    addPatientPracticeSurveyResult(surveyResult) {
        const patientId = surveyResult.patientId
        this.practiceSurveyResults[patientId] = [...(this.practiceSurveyResults[patientId] || []), surveyResult]
    }

    // Add an array of patient results.
    addPatientPracticeSurveyResults(surveyResults) {
        if (surveyResults.length > 0) {
            const patientId = surveyResults[0].patientId
            this.practiceSurveyResults[patientId] = [...(this.practiceSurveyResults[patientId] || []), ...surveyResults]
        }
    }

    // Replace the entire set of results for a patient.
    setPatientPracticeSurveyResults(surveyResults) {
        if (surveyResults.length > 0) {
            const patientId = surveyResults[0].patientId
            this.practiceSurveyResults[patientId] = surveyResults
        }
    }

    /**
     * Given an array of survey results, return the correct filtered set according to the user role and
     * capabilities.
     */
    getSurveyResultsForUser(surveyResults, user) {
        const uid = user.personaUid
        if (user.has(User.Capability.canLeadTeam)) {
            return surveyResults.filter(surveyResult => surveyResult.personaUid == uid)
        }

        return surveyResults
    }

    /**
     * As part of initial data resolution, set chartScheduleSlug and chartScheduleLabel on all surveyResults.
     */
    setSurveyResultChartScheduleSlugs(surveyResults) {
        if (
            !(
                store.state.user.owner &&
                store.state.user.owner.keyValues &&
                store.state.user.owner.keyValues.dash &&
                store.state.user.owner.keyValues.dash.charts
            )
        ) {
            return
        }
        const scheduleSlugs = store.state.user.owner.keyValues.dash.charts.promSchedules || []

        // First, set result.chartScheduleSlug to be the scheduleSlugs bucket the result falls within
        // (or undefined if none).
        // We assume that all patients are loaded, and any milestones referenced by scheduleSlugs are already set.
        for (const result of surveyResults) {
            // Consider only PROM results
            const survey = store.state.content.surveys[result.surveySlug]
            if (!survey || !survey.isPromSurvey) {
                continue
            }
            const user = store.state.user.user
            if (user.isExec) {
                // We have patient primary milestone date within the SurveyResult object
                if (result.primaryMilestoneDate == undefined) {
                    Logging.warn(`SurveyResult has no primaryMilestoneDate for patientId: ${result.patientId}`)
                } else {
                    const primaryMilestoneMoment = moment(result.primaryMilestoneDate)
                    result.chartScheduleSlug = PatientService.getPatientScheduleActiveAtMoment(
                        primaryMilestoneMoment,
                        scheduleSlugs,
                        result.moment
                    )
                }
            } else {
                // We have all patient milestone dates
                const patient = store.state.user.patients[result.patientId]
                result.chartScheduleSlug = PatientService.getPatientScheduleActiveAtMoment(
                    patient,
                    scheduleSlugs,
                    result.moment
                )
            }
            if (result.chartScheduleSlug == undefined) {
                // This can happen frequently in testing, e.g. if people create results pre-injury in the jhub case
                Logging.warn(
                    `SurveyResult.moment ${result.moment.format(Utils.serialisedDateFormat)} for patient ${
                        result.patientId
                    }, survey ${result.surveySlug} did not fall within any of the specified schedules: ${scheduleSlugs}`
                )
                // // For testing, allow debug of same call
                // result.chartScheduleSlug = PatientService.getPatientScheduleActiveAtMoment(
                //     patient,
                //     scheduleSlugs,
                //     result.moment
                // )
            }
            // 22/09/21 If result.chartScheduleSlug is undefined (e.g. survey result was not within any PROM window),
            // then the below will also be undefined
            result.chartScheduleLabel = Locale.getLanguageItemOrUndefined(result.chartScheduleSlug)
        }
    }

    /**
     * Given a source (cohortResults) and target (surveyResults) array of SurveyResults, calculate the average
     * result score values(s) for each target result, by averaging over all source results.
     * Add the following properties to the SurveyResult objects:
     * - baseline{sectionName} ... average score over all source results at the matching schedule interval
     * - baselineNumPatients ... the number of distinct patients averaged over
     *
     * The scheduleSlugs are provided from a list in the config (Owner or Lead), and must be consistent across all
     * charts that wish to aggregated results.
     *
     * NOTE: A baseline... value is calculated for every ScoreSection defined by the survey. For example, for
     * promis10-surv we would calculated 3 values:
     * - baselineScore
     * - baselinePhysical
     * - baselineMental
     * Typically, when SurveyResults are converted into minimal objects to be used as a chart dataset, the relevant
     * score section baseline value is copied into a property 'baselineScore'.
     */
    calculateSurveyResultsAveragesFromCohortResults(surveyResults, cohortResults) {
        /**
         * Calculate practice averages across all results for each (surveySlug, scheduleSlug) tuple
         * and store those on every result.
         */
        let averageScoreMap = {} // surveySlug to map of { sectionName: value }
        let averageNumPatientsMap = {} // surveySlug to numPatients (unique patients with result for this survey)

        for (const result of surveyResults) {
            // Consider only PROM results
            const surveySlug = result.surveySlug
            const survey = store.state.content.surveys[surveySlug]
            if (!survey || !survey.isPromSurvey) {
                continue
            }
            const scheduleSlug = result.chartScheduleSlug
            const sectionConfigs = (survey.keyValues || {}).scoreSections || [{ name: 'Score' }]
            const tuple = `${surveySlug}-${scheduleSlug}`

            let scoreMap = averageScoreMap[tuple]
            let numPatients = averageNumPatientsMap[tuple]
            if (scoreMap != undefined) {
                // We've already calculated the averages for this (survey, schedule): copy existing
                result.baselineNumPatients = numPatients
                if (!numPatients) {
                    result.baselineScore = undefined
                }
                Object.keys(scoreMap).forEach(sectionName => {
                    const key = `baseline${sectionName}`
                    result[key] = scoreMap[sectionName]
                })
            } else {
                // We have not yet calculated the average for this tuple: calculate and set on this result
                let validResults = cohortResults.filter(result => {
                    return (
                        result.surveySlug == surveySlug &&
                        (result.chartScheduleSlug
                            ? result.chartScheduleSlug == scheduleSlug
                            : result.scheduleSlug == scheduleSlug)
                    )
                })
                const invalidResults = validResults.filter(result => result.scoreMap == undefined)
                if (invalidResults.length > 0) {
                    Logging.warn(`Some results for survey average tuple ${tuple} have undefined scoreMap, removing...`)
                    validResults = validResults.filter(result => result.scoreMap != undefined)
                }

                scoreMap = {}
                if (validResults.length != 0) {
                    // Get average score, for this survey, for this schedule (start offset)
                    sectionConfigs.forEach(sectionConfig => {
                        scoreMap[sectionConfig.name] =
                            validResults.reduce(
                                (avg, result) => avg + ((result.scoreMap[sectionConfig.name] || {}).value || 0),
                                0
                            ) / validResults.length
                    })
                }

                // Get number of unique patients contributing to this average
                const patientIds = new Set(validResults.map(result => result.patientId))
                numPatients = patientIds.size
                averageNumPatientsMap[tuple] = numPatients
                averageScoreMap[tuple] = scoreMap
                result.baselineNumPatients = numPatients
                if (!numPatients) {
                    result.baselineScore = undefined
                }
                sectionConfigs.forEach(sectionConfig => {
                    const key = `baseline${sectionConfig.name}`
                    result[key] = scoreMap[sectionConfig.name]
                })
            }
        }
    }

    /**
     * For an array of SurveyResults, set chartScheduleSlug to be the scheduleSlugs bucket the result falls within(or undefined if none).
     */
    calculateSurveyResultsChartScheduleSlugs(surveyResults) {
        // NOTE: We assume these match the schedule slugs in the dataset
        const scheduleSlugs = store.state.user.owner.keyValues.dash.charts.promSchedules || [
            'pre-op',
            '3m-6m-post-op',
            '6m-1y-post-op',
            '1y-2y-post-op'
        ]

        // We assume that all patients are loaded, and any milestones referenced by scheduleSlugs are already set.
        for (const result of surveyResults) {
            // Consider only PROM results
            const survey = store.state.content.surveys[result.surveySlug]
            if (!survey || !survey.isPromSurvey) {
                continue
            }
            const patient = store.state.user.patients[result.patientId]

            if (!patient) {
                Logging.warn(
                    `Could not get Patient from SurveyResult: patient ${result.patientId}, survey ${result.surveySlug}`
                )
                continue
            }

            const patientJourney = patient.pathwayJourneys.find(
                patientJourney => patientJourney.patientJourneyId == result.patientJourneyId
            )

            result.chartScheduleSlug = PatientService.getPatientScheduleActiveAtMoment(
                patient,
                scheduleSlugs,
                result.moment,
                patientJourney
            )

            if (result.chartScheduleSlug == undefined) {
                // This can happen frequently in testing, e.g. if people create results pre-injury in the jhub case
                Logging.warn(
                    `SurveyResult.moment ${result.moment.format(Utils.serialisedDateFormat)} for patient ${
                        result.patientId
                    }, survey ${result.surveySlug} did not fall within any of the specified schedules: ${scheduleSlugs}`
                )
            }
            // 22/09/21 If result.chartScheduleSlug is undefined (e.g. survey result was not within any PROM window),
            // then the below will also be undefined
            result.chartScheduleLabel = Locale.getLanguageItemOrUndefined(result.chartScheduleSlug)

            if (result.chartScheduleLabel == undefined) {
                Logging.error(
                    `SurveyResultsService: Missing chartScheduleLabel for chartScheduleSlug: ${result.chartScheduleSlug}`
                )
            }
        }
    }

    /**
     * Given a source report dataset and target (surveyResults) array of SurveyResults, copy the average
     * result value for each target result from the single matching report row.
     * We filter the rows by matching groupingCategory (department, provider, region) and groupingField (e.g. 'GB').
     */
    calculateSurveyResultsAveragesFromReportAverages(surveyResults, dataset, groupingCategory, groupingField) {
        this.calculateSurveyResultsChartScheduleSlugs(surveyResults)
        for (const result of surveyResults) {
            // Consider only PROM results
            const survey = store.state.content.surveys[result.surveySlug]
            if (!survey || !survey.isPromSurvey) {
                continue
            }
            const surveySlug = result.surveySlug
            const scheduleSlug = result.chartScheduleSlug
            const rowsMatching = dataset.filter(
                row =>
                    row.scheduleSlug == scheduleSlug &&
                    row.surveySlug == surveySlug &&
                    row.groupingCategory == groupingCategory &&
                    row.groupingField == groupingField
            )
            if (rowsMatching.length > 0) {
                const row = rowsMatching[0]
                result.baselineScore = Math.round(row.average)
                result.baselineNumPatients = row.totalPatients
            } else {
                result.baselineScore = undefined
                result.baselineNumPatients = 0
            }
        }
    }

    /**
     * For a specified patientJourney and baseline type (groupingCategory), get the relevant
     * groupingField. Examples:
     * baseline=department, groupingField=patientJourney team department slug
     * baseline=provider, groupingField=patientJourney team provider slug
     * baseline=region, groupingField=patientJourney team region tag value
     */
    getPatientJourneyBaselineGroupingField(patientJourney, baseline) {
        const team = store.state.user.teams[patientJourney.teamId]
        if (!team) {
            return undefined
        }
        switch (baseline) {
            default:
                return undefined
            case SurveyResultsChartSeries.Baseline.department:
                return team.departmentSlug
            case SurveyResultsChartSeries.Baseline.provider:
                return team.providerSlug
            case SurveyResultsChartSeries.Baseline.region:
                var provider = store.state.resources.providers[team.providerSlug]

                return provider ? provider.region : undefined
        }
    }

    /**
     * From an array of SurveyResults, get a list of procedure codes.
     */
    getProcedureCodesFromResults(surveyResults) {
        return _.uniq(surveyResults.map(result => result.procedureCode)).sort()
    }

    /**
     * Fill out popup with survey result.
     */
    showPatientSurveyResult(patient, surveyResult, patientJourneyMilestone) {
        store.commit('popup/setConfig', {
            patient: patient,
            surveyResult: surveyResult,
            patientJourneyMilestone: patientJourneyMilestone
        })
        store.commit('popup/setClass', 'PopupPatientSurveyResult')
    }

    /**
     * Request and display a full patient survey result:
     * - Finds the SurveyResult object, based on the parameters
     * - If the full StepResult array is not present, request the full report from the server
     * - Set up the Vuex popup values and display the popup
     */
    requestAndDisplayPatientSurveyResult(surveyResult) {
        const patient = store.state.user.patients[surveyResult.patientId]
        const patientJourneyMilestone = patient.firstJourney
            .getMilestonesOfType(Milestone.Type.surveyReview)
            .find(milestone => milestone.resultPrmId == surveyResult.resultPrmId)

        if (!_.isEmpty(surveyResult.stepResults)) {
            // Have previously requested full result, display now
            this.showPatientSurveyResult(patient, surveyResult, patientJourneyMilestone)
        } else {
            Logging.error(`SurveyResult ${surveyResult.resultPrmId} has no StepResults`)
        }
    }

    /**
     * Display a patient's survey for completion by a clinician
     */
    displayPatientSurveyForClinicianCompletion({ clinicalMilestone, patient, amendResult, resetSurveyResult }) {
        if (!clinicalMilestone || !patient) {
            return
        }

        if (amendResult) {
            clinicalMilestone.surveyResult.createdById = store.state.user.user.personaId
        }

        if (resetSurveyResult) {
            clinicalMilestone.surveyResult = null
        }

        store.commit('popup/setConfig', {
            clinicalMilestone: clinicalMilestone,
            patient: patient,
            amendResult: amendResult ?? clinicalMilestone.surveyResult
        })
        store.commit('popup/setClass', 'PopupPatientSurveyClinicianComplete')
    }

    /**
     * Display a patient's existing survey result for amendment by a clinician
     */
    displayPatientSurveyForClinicianAmend(clinicalMilestone, patient) {
        clinicalMilestone.surveyResult.enteredByClinician = true
        clinicalMilestone.surveyResult.createdById = store.state.user.user.personaId

        store.commit('popup/setConfig', {
            clinicalMilestone: clinicalMilestone,
            patient: patient,
            surveyResult: clinicalMilestone.surveyResult,
            amendResult: true
        })
        store.commit('popup/setClass', 'PopupPatientSurveyClinicianComplete')
    }

    /*
     * Display a popup confirmation to amend a patient's existing survey result
     */
    displayConfirmAmendPatientSurveyResult(clinicalMilestone, patient) {
        store.commit('popup/setConfig', {
            title: Locale.getLanguageItem('clinicalMilestonePopupAmendPatientResultTitle'),
            body: Locale.getLanguageItem('clinicalMilestonePopupAmendPatientResultBody'),
            leftButtonText: Locale.getLanguageItem('cancel'),
            rightButtonText: Locale.getLanguageItem('ok'),
            onRightButton: () => this.displayPatientSurveyForClinicianAmend(clinicalMilestone, patient),
            onLeftButton: () => store.commit('popup/dismiss'),
            isLeftButtonSecondary: true
        })
        store.commit('popup/setClass', 'PopupTwoButtons')
    }

    /*
     * Display a popup confirmation to discard a patient's partially completed survey result
     * and start a new one
     */
    displayConfirmDiscardPatientSurveyResult(clinicalMilestone, patient) {
        store.commit('popup/setConfig', {
            title: Locale.getLanguageItem('clinicalMilestonePopupPatientPartialResultTitle'),
            body: Locale.getLanguageItem('clinicalMilestonePopupPatientPartialResultBody', [
                patient.fullName,
                Locale.getLanguageItem('clinicalMilestonePopupPatientPartialResultAccept')
            ]),
            leftButtonText: Locale.getLanguageItem('cancel'),
            rightButtonText: Locale.getLanguageItem('clinicalMilestonePopupPatientPartialResultAccept'),
            onRightButton: () => this.invalidateSurveyResult(clinicalMilestone, patient),
            onLeftButton: () => store.commit('popup/dismiss'),
            isLeftButtonSecondary: true
        })
        store.commit('popup/setClass', 'PopupTwoButtons')
    }

    /**
     * Sets a survey result as isValid=false.
     * Invalid results are not returned or displayed in the patient's survey list.
     */
    invalidateSurveyResult(clinicalMilestone, patient) {
        const surveyResult = clinicalMilestone.surveyResult
        const journey = store.state.resources.journeys[surveyResult.journeySlug]
        const activity = journey.activitiesMap[surveyResult.activitySlug]

        const payload = SurveyService.createSurveyResultPostPayload({
            survey: activity.content,
            surveyResult,
            patient,
            patientJourney: patient.firstJourney,
            activity
        })
        payload.uuid = nanoid()
        payload.isValid = false

        const url = Request.Stem.patientWebSurveyResultUpdate
            .replace('{patientId}', patient.patientId)
            .replace('{resultId}', surveyResult.id)
        Request.put(url, payload)
            .then(() => {
                surveyResult.isValid = false
                this.displayPatientSurveyForClinicianCompletion({
                    clinicalMilestone,
                    patient,
                    amendResult: false,
                    resetSurveyResult: true
                })
            })
            .catch(() => {
                NotifyService.error(Locale.getLanguageItem('surveyResultPostFailure'))
                Logging.error(
                    `Could not invalidate survey result ${surveyResult.resultPrmId} for patient ${patient.personaId}. Logged in as clinician ${store.state.user.user.personaId}`
                )
            })
    }

    /**
     * From an array of SurveyResults, remove all entries corresponding to partial results, if a completed result
     * exists for the same uuid.
     */
    surveyResultsRemovePartialIfCompleted(surveyResults) {
        const completeResults = surveyResults.filter(sr => sr.status == 'complete')
        const completeResultUuids = completeResults.map(sr => sr.uuid)

        return surveyResults.filter(sr => !(sr.status == 'partial' && completeResultUuids.includes(sr.uuid)))
    }

    /**
     * From an array of SurveyResults, remove all entries corresponding to partial results.
     */
    surveyResultsRemovePartial(surveyResults) {
        return surveyResults.filter(sr => !(sr.status == 'partial'))
    }

    /**
     * From the report that returns patient survey results, created SurveyResult objects and store on patient.
     * Also update practiceSurveyResults.
     */
    setPatientSurveyResultsJson(patient, surveyResultsJson) {
        const surveyResults = surveyResultsJson.map(object => {
            object.patientId = patient.patientId
            // Resolve activity slug
            const journey = store.state.resources.journeys[object.journeySlug]
            const activity = journey.activities.find(
                activity => activity.contentSlug == object.surveySlug && activity.scheduleSlug == object.scheduleSlug
            )
            if (activity) {
                object.activitySlug = activity.slug
            }
            // Make empty StepResults for the entire survey. These may be replaced by an additional report of step results
            let survey = this.getSurveyByVersion(object.surveySlug, object.version)

            if (survey) {
                object.stepResults = this.makeStepResults(survey.steps)
            }

            return new SurveyResult(object)
        })
        patient.setSurveyResults(surveyResults)
        this.setPatientPracticeSurveyResults(surveyResults)
    }

    getSurveyByVersion(surveySlug, version) {
        let survey = store.state.content.content[surveySlug]
        const surveyResultVersion = version || 1

        if (survey?.version && survey.version != surveyResultVersion) {
            const currentVersionSurvey = survey.versions.find(
                surveyWithOtherVersion => surveyWithOtherVersion.version == surveyResultVersion
            )

            if (currentVersionSurvey) {
                survey = currentVersionSurvey
            }
        }

        return survey
    }

    /**
     * Sanitise the patient survey step results report response.
     * 04/01/22 Removed legacy code that merged choice and free-text values.
     */
    sanitisePatientSurveyStepResultsResponse(stepResults) {
        stepResults.forEach(row => {
            // Duplicate start/end time if only one defined. Added initially as ROM survey step results had undefined endTime
            row.startTime = row.startTime || row.endTime
            row.endTime = row.endTime || row.startTime
        })
        if (ConfigManager.singlePatientId) {
            // Debugging: list results on PJs
            const patientJourneyStepResults = _.groupBy(stepResults, 'patientJourneyId')
            Object.keys(patientJourneyStepResults).forEach(patientJourneyId => {
                const results = patientJourneyStepResults[patientJourneyId]
                const questionSlugResults = _.groupBy(results, 'questionSlug')
                const patientJourney = store.state.user.patientJourneys[patientJourneyId]
                if (!patientJourney) {
                    Logging.error(`Could not find PatientJourney: ${patientJourneyId}`)
                } else {
                    const journey = store.state.resources.journeys[patientJourney.journeySlug]
                    Logging.log(
                        `Found ${results.length} StepResults for patientJourney ${patientJourneyId} (${
                            journey.slug
                        }): ${JSON.stringify(Object.keys(questionSlugResults), 0, null)}`
                    )
                }
            })
        }

        return stepResults
    }

    /**
     * From the report that returns patient survey step results, create StepResult objects and store them
     * on the patient's SurveyResult objects. We can match StepResults to SurveyResults using resultPrmId
     */
    setPatientSurveyStepResultsJson(patient, stepResults) {
        // Filter out any StepResults for Steps we don't have, and warn
        ResourceService.clearValidationLog()

        stepResults = ResourceService.validateDatasetStepSlugs(stepResults, `Patient ${patient.personaId}`)
        if (ResourceService.validationLog) {
            Logging.warn(ResourceService.validationLog)
        }

        // Get all SurveyResults referenced by the StepResults
        const resultPrmIds = stepResults.reduce((total, sr) => total.add(sr.resultPrmId), new Set())
        const surveyResults = [...resultPrmIds].reduce((total, id) => {
            const surveyResult = patient.surveyResults.find(sr => sr.resultPrmId == id)
            if (surveyResult) {
                total.push(surveyResult)
            }

            return total
        }, [])

        // To improve performance, we create a helper map of (resultPrmId, stepSlug) to StepResult
        const helperMap = {}
        stepResults.forEach(object => {
            const key = `${object.resultPrmId}.${object.contentSlug}` // in JSON, we use contentSlug
            helperMap[key] = new StepResult(object)
        })

        // Iterate through SurveyResults, then Steps, finding StepResults in correct order
        patient.surveyStepResults = []
        for (const surveyResult of surveyResults) {
            let survey = this.getSurveyByVersion(surveyResult.surveySlug, surveyResult.version)

            if (!survey) {
                Logging.warn(
                    `Could not find survey ${surveyResult.surveySlug} for SurveyResult ${surveyResult.resultPrmId}`
                )
                continue
            }

            surveyResult.stepSlugToStepResult = {}
            for (const step of survey.steps) {
                if (step instanceof InfoStep) {
                    continue
                }
                // Find StepResult for this Step of this SurveyResult
                const key = `${surveyResult.resultPrmId}.${step.slug}`
                const stepResult = helperMap[key]
                if (!stepResult) {
                    Logging.warn(
                        `Could not find StepResult ${step.slug} for SurveyResult ${surveyResult.resultPrmId}, adding nothing to SurveyResult`
                    )
                    continue
                }

                // The report endpoint only returns steps with a result value, so we can directly
                // replace/update these to keep non-returned StepResult instances intact
                const existingStepInstanceIndex = surveyResult.stepResults.findIndex(
                    stepResult => stepResult.stepSlug == step.slug
                )

                if (existingStepInstanceIndex >= 0) {
                    surveyResult.stepResults[existingStepInstanceIndex] = stepResult
                }

                surveyResult.stepSlugToStepResult[stepResult.stepSlug] = stepResult
            }
            patient.surveyStepResults = [...patient.surveyStepResults, surveyResult.stepResults]
        }
    }

    // From an array of SurveyResults, get the list of survey slugs.
    getSurveySlugsFromSurveyResults(surveyResults, sorted) {
        const surveySlugs = [...new Set(surveyResults.map(result => result.surveySlug))]
        if (sorted) {
            return surveySlugs.sort((a, b) => {
                const surveyA = store.state.content.surveys[a]
                const surveyB = store.state.content.surveys[b]

                return surveyA.name.localeCompare(surveyB.name)
            })
        }

        return surveySlugs
    }

    /**
     * Get a survey "score section label", from an amalgamated survey slug and section name,
     * for example "hoosps-surv Score" or "promis10-surv Physical".
     * If section name is "Score", this is just the Survey.name field.
     * Otherwise, it's the survey name field, followed by the section name in brackets.
     */
    getSurveySlugScoreSectionLabel(slugSection) {
        const firstSpaceIndex = slugSection.indexOf(' ')
        const surveySlug = slugSection.substring(0, firstSpaceIndex)
        const sectionName = slugSection.substring(firstSpaceIndex + 1)
        const survey = store.state.content.surveys[surveySlug]
        if (sectionName == 'Score') {
            return survey.name
        }

        return `${survey.name} (${sectionName})`
    }

    // From an array of SurveyResults, get the list of survey slugs.
    getPromSurveySlugsFromSurveyResults(surveyResults, sorted) {
        return this.getSurveySlugsFromSurveyResultsWithAnyTags(surveyResults, [Survey.promSurveyTag], sorted)
    }

    /**
     * Get all "slug sections" for all matching stored surveys.
     * A "slug section" is a survey slug followed by a section name, e.g. "promis10-surv Score" or "promis10-surv Physical".
     * By default, we include all stored surveys tagged as prom-survey.
     * By default, we include the "Score" section, or any sections explicitly named in the Survey.keyValues.
     *
     * If we have config.journeySlug, then we include all PROM surveys on the journey, ONLY if their schedules
     * reference an existing PJM, or the primaryMilestone. Also if the primaryMilestone model has a subtype
     * (e.g. AppointmentMilestone) then we include the survey only if the patient already has a result (this avoids the
     * situation where we display legends for all possible content-bundled PROMs, where some may be bundled against
     * subtype-specific milestones that will never be added).
     */
    getPromSurveySlugSections(config) {
        const slugSections = []
        let surveys = []
        if (config && config.journeySlug) {
            // All prom surveys on the specified journey
            const journey = store.state.resources.journeys[config.journeySlug]
            let activities = journey.activities.filter(activity => {
                const survey = store.state.content.surveys[activity.contentSlug]

                return survey && survey.isPromSurvey
            })
            if (config.patientJourney) {
                activities = activities.filter(activity => {
                    const schedule = Schedule.get(activity.scheduleSlug)

                    // Some milestones exist on the globalJourney
                    let patientJourney = config.patientJourney
                    if (config.globalJourney && MilestoneService.isMilestoneSlugGlobal(schedule.milestone)) {
                        patientJourney = config.globalJourney
                    }
                    if (
                        patientJourney.getMilestoneOfSlug(
                            schedule.milestone,
                            undefined, // qualifier
                            false, // includeInactive
                            schedule.milestoneSubtype
                        )
                    ) {
                        // PJM exists, with correct subtype (if specified)
                        return true
                    }
                    if (schedule.milestone != config.patientJourney.primaryMilestoneSlug) {
                        return false
                    }
                    // schedule references primaryMilestone, and no PJMs of this type and subtype exist,
                    // OR subtype is not defined. Return true only if survey results exist
                    const surveyResult = patientJourney.surveyResults.find(
                        surveyResult => surveyResult.surveySlug == activity.contentSlug
                    )

                    return !!surveyResult
                })
            }
            const surveySlugs = _.uniq(activities.map(activity => activity.contentSlug))
            surveys = surveySlugs.map(slug => store.state.content.surveys[slug])
        } else {
            // All prom surveys
            surveys = Object.values(store.state.content.surveys).filter(survey => survey.isPromSurvey)
        }
        surveys.forEach(survey => {
            if (survey.isPromSurvey) {
                const sectionConfigs = (survey.keyValues || {}).scoreSections || [
                    {
                        name: 'Score'
                    }
                ]
                sectionConfigs.forEach(sectionConfig => {
                    if (sectionConfig.display !== false) {
                        slugSections.push(`${survey.slug} ${sectionConfig.name}`)
                    }
                })
            }
        })
        // Sort by the associated titles
        slugSections.sort((a, b) => {
            return this.getSurveySlugScoreSectionLabel(a).localeCompare(this.getSurveySlugScoreSectionLabel(b))
        })

        return slugSections
    }

    /**
     * From an array of SurveyResults, and a specified survey slug and section name, return an object providing the
     * various things needed to render a line series.
     * The function works in 2 scenarios:
     * - On the Patient page, where surveyResults is an array of SurveyResult objects
     * - On the Overview page, where surveyResults is an array of objects each representing an aggregate
     */
    getChartConfigFromSurveyResultsForSlugSection(surveyResults, survey, sectionName) {
        let dataset
        const slugSection = `${survey.slug} ${sectionName}`
        const baselineKey = `baseline${sectionName}` // e.g. baselineScore, baselinePhysical
        if (surveyResults.length == 0) {
            dataset = []
        } else if (surveyResults[0].scoreSection) {
            // Overview page
            dataset = surveyResults.filter(obj => obj.scoreSection == slugSection)
        } else {
            // Patient page
            dataset = surveyResults
                .filter(surveyResult => surveyResult.surveySlug == survey.slug)
                .map(surveyResult => {
                    const patientJourney = store.state.resources.journeys[surveyResult.journeySlug]

                    return {
                        surveyResult: surveyResult, // required to open result popup
                        score: (surveyResult.scoreMap[sectionName] || {}).value,
                        time: surveyResult.time,
                        moment: surveyResult.moment,
                        chartScheduleLabel: surveyResult.chartScheduleLabel,
                        baselineScore: surveyResult[baselineKey],
                        baselineNumPatients: surveyResult.baselineNumPatients,
                        patientJourneyTitle: patientJourney ? patientJourney.titleShortened : ''
                    }
                })
        }
        const config = {
            dataset: dataset
        }
        if (sectionName == 'Score') {
            config.title = survey.name
            config.minY = survey.minScore
            config.maxY = survey.maxScore
        } else {
            config.title = this.getSurveySlugScoreSectionLabel(slugSection)
            const section = survey.keyValues.scoreSections.find(ss => ss.name == sectionName)
            config.minY = section.minScore
            config.maxY = section.maxScore
        }

        return config
    }

    /**
     * From an array of SurveyResults, and a specified Step, return a dataset (array) suitable for rendering, where each
     * object is the value of a StepResult.
     */
    getChartDatasetFromStepResultsForStepSlug(surveyResults, step) {
        const stepSlug = step.slug
        const dataset = surveyResults.reduce(function (dataset, surveyResult) {
            const stepResult = surveyResult.stepSlugToStepResult[stepSlug]
            if (stepResult) {
                // Set bulletText property on existing StepResult object
                let bulletText
                if (step.choiceValueToText) {
                    bulletText = `${step.text}\n"${step.choiceValueToText[stepResult.valueInt]}"`
                } else {
                    return dataset
                }

                const patientJourney = store.state.resources.journeys[surveyResult.journeySlug]

                return dataset.concat({
                    valueInt: stepResult.valueInt, // plotted y value
                    score: stepResult.valueInt, // bullet hover value
                    time: surveyResult.time,
                    moment: surveyResult.moment,
                    bulletText: bulletText, // used for non-numeric, e.g. pain-meds question
                    surveyResult: surveyResult, // required to open result popup,
                    stepSlug: stepSlug,
                    patientJourneyTitle: patientJourney ? patientJourney.titleShortened : ''
                })
            }

            return dataset
        }, [])

        return dataset
    }

    /**
     * From a SurveyResult, get the summary text to display, respecting any configuration of score sections.
     */
    getSurveyResultScoreSectionsSummary(surveyResult) {
        const survey = store.state.content.surveys[surveyResult.surveySlug]
        const sectionConfigs = (survey.keyValues || {}).scoreSections || [
            {
                name: 'Score'
            }
        ]
        const rows = []
        for (const sectionConfig of sectionConfigs) {
            if (sectionConfig.display !== false) {
                const sectionScore = surveyResult.scoreMap[sectionConfig.name]
                if (!sectionScore) {
                    continue
                }
                if (sectionConfig.name == 'Score') {
                    rows.push(
                        Locale.getLanguageItem('surveyDetailScore', [Math.round(sectionScore.value), survey.maxScore])
                    )
                } else {
                    rows.push(`${sectionConfig.name}: ${Math.round(sectionScore.value)} / ${sectionConfig.maxScore}`)
                }
            }
        }

        return rows.join('\n')
    }

    /**
     * From an array of surveys, get all score sections, as "slugSections", e.g. "foo-surv Physical".
     * By default we remove those that are configured as "display: false"
     */
    getSurveysScoreSlugSections(surveys, includeHidden) {
        const slugSections = surveys.reduce((total, survey) => {
            return [...total, ...survey.getScoreSlugSections(includeHidden)]
        }, [])

        return _.uniq(slugSections)
    }

    // From an array of SurveyResults and tags, get the list of survey slugs for results with surveys with any tag.
    getSurveySlugsFromSurveyResultsWithAnyTags(surveyResults, tags, sorted) {
        let surveySlugs = surveyResults.reduce(function (set, surveyResult) {
            const survey = store.state.content.surveys[surveyResult.surveySlug]
            if (survey == undefined) {
                Logging.error(`No Survey object for surveyResult.surveySlug: ${surveyResult.surveySlug}`)
            } else {
                if (_.intersection(survey.tags, tags).length > 0) {
                    set.add(surveyResult.surveySlug)
                }
            }

            return set
        }, new Set())
        surveySlugs = [...surveySlugs]
        if (sorted) {
            return surveySlugs.sort((a, b) => {
                const surveyA = store.state.content.surveys[a]
                const surveyB = store.state.content.surveys[b]

                return surveyA.name.localeCompare(surveyB.name)
            })
        }

        return surveySlugs
    }

    /**
     * From a list of SurveyResults, find all complete results for a survey within the specified schedule,
     * for a given patient.
     */
    getSurveyResultsCompletedWithinScheduleForPatient(
        surveyResults,
        surveySlug,
        scheduleSlug,
        patient,
        patientJourney
    ) {
        const results = (surveyResults || []).filter(surveyResult => {
            return (
                surveyResult.surveySlug == surveySlug &&
                PatientService.isPatientScheduleActiveAtMoment(
                    patient,
                    scheduleSlug,
                    surveyResult.moment,
                    patientJourney
                )
            )
        })
        // Return in reverse-chrono order, i.e. most recent first
        results.sort((resultA, resultB) => resultB.moment.diff(resultA.moment))

        return results
    }

    /**
     * Find all activity data within the specified schedule, for a given patient.
     * Assume patient.activityData already sorted increasing with time - most recent result will be
     * LAST in the array.
     */
    getActivityDataWithinScheduleForPatient(scheduleSlug, patient) {
        const [startMoment, endMoment] = PatientService.getPatientScheduleStartEndMoments(patient, scheduleSlug)
        const data = (patient.activityData || []).filter(activityData => {
            const periodMoment = moment(activityData.period)

            return periodMoment.isSameOrAfter(startMoment) && periodMoment.isSameOrBefore(endMoment)
        })

        return data
    }

    /**
     * Get the list of available baselines for a patient surveys chart:
     * - all ... always added
     * - department ... added if defined on team (and not equal to provider)
     * - provider ... added if defined on team
     * - region ... added if specified on provider
     */
    getPatientBaselines(patient) {
        // First entry is 'All patients'
        const baselines = [SurveyResultsChartSeries.Baseline.all]

        // If the patient’s Lead is part of a Department, add the option to switch baseline to the average
        // of results for patients in the same Department
        const team = store.state.user.teams[patient.teamId]
        if (!team) {
            Logging.error(`Could not find team: ${patient.teamId}`)

            return baselines
        }
        if (team.departmentSlug && team.departmentSlug != team.providerSlug) {
            baselines.push(SurveyResultsChartSeries.Baseline.department)
        }

        // If we have multiple Departments from the patient's Provider, provide the option to switch baseline
        // to the average of results for patients in the same Provider
        const provider = store.state.resources.providers[team.providerSlug]
        if (!provider) {
            Logging.error(`Could not find provider: ${team.providerSlug}`)
        } else {
            baselines.push(SurveyResultsChartSeries.Baseline.provider)
        }

        // If the patient's provider specifies a region provide the option to switch baseline
        // to the average of results for patients in the same Region (with the same Journey)
        if (provider && provider.region) {
            baselines.push(SurveyResultsChartSeries.Baseline.region)
        }

        return baselines
    }

    /**
     * Make StepResult for a Survey.
     * Ported from mobile StepResultFactory.makeStepResult
     */
    makeStepResult(step) {
        switch (step.type) {
            case Step.Type.healthScale:
            case Step.Type.infoStep:
            case Step.Type.painScale:
            case Step.Type.question:
            case Step.Type.sliderScale:
                return new StepResult({
                    contentSlug: step.slug,
                    results: [],
                    minSelected: step.minSelected
                })
            default:
                return undefined
        }
    }

    /**
     * Make StepResults for a Survey.
     * Ported from mobile ActivityResultFactory.makeStepResults
     */
    makeStepResults(steps) {
        let stepResults = steps.map(step => this.makeStepResult(step))

        return stepResults
    }

    /**
     * Make a SurveyResult for a Survey.
     * Ported from mobile ActivityResultFactory.makeTaskResult
     */
    makeSurveyResult(survey, scheduleSlug, multiple, createdBy, patient) {
        const stepResults = this.makeStepResults(survey.steps)
        if (stepResults.length == 0) {
            Logging.warn(`StepResults array for content slug: ${survey.slug} is empty`)
        }
        // NOTE: uuid is set only when POSTing.
        // id, resultPrmId are set only after initial POST response.
        const surveyResult = new SurveyResult({
            surveySlug: survey.slug,
            scheduleSlug: scheduleSlug,
            stepResults: stepResults,
            endTime: moment().format(Utils.serialisedDateFormat),
            createdById: createdBy?.personaId,
            enteredByClinician: createdBy?.persona == User.Persona.clinician,
            isWebSurveyResult: true,
            patientId: patient?.personaId,
            patientJourneyId: patient?.patientJourneyId,
            multiple: multiple
        })
        surveyResult.status = SurveyResult.Status.partial

        return surveyResult
    }

    /**
     * Post-process a map of survey averages, which will become a chart data source.
     * Currently 1 step:
     * - Replace or amalgamate any 'post-reg' data with any qualifying pre-op data
     */
    postProcessSurveyScheduleAverages(mappedScheduleAverages, scheduleSlugs) {
        const earliestSchedule = Schedule.get(scheduleSlugs[0])
        if (earliestSchedule.milestone != 'registration' && earliestSchedule.startOffset <= -365) {
            Object.keys(mappedScheduleAverages).forEach(procedureTitle => {
                const surveyData = mappedScheduleAverages[procedureTitle]
                if (surveyData['post-reg']) {
                    if (surveyData[earliestSchedule.slug]) {
                        // Amalgamate
                        const scheduleData = {
                            totalScore:
                                surveyData['post-reg'].totalScore + surveyData[earliestSchedule.slug].totalScore,
                            denominator:
                                surveyData['post-reg'].denominator + surveyData[earliestSchedule.slug].denominator,
                            patientIds: new Set(
                                [...surveyData['post-reg'].patientIds],
                                ...surveyData[earliestSchedule.slug].patientIds
                            )
                        }
                        surveyData[earliestSchedule.slug] = scheduleData
                    } else {
                        // Replace
                        surveyData[earliestSchedule.slug] = surveyData['post-reg']
                    }
                    delete surveyData['post-reg']
                }
            })
        }
    }

    hasSurveyPromDeteriorationTask(surveyResult) {
        const owner = store.state.user.owner
        const survey = store.state.content.surveys[surveyResult.surveySlug]
        const hasPromDeteriorationTask =
            owner.keyValues.dash.charts.surveyResult &&
            owner.keyValues.dash.charts.surveyResult.hasPromDeteriorationTask

        return survey.isPromSurvey && hasPromDeteriorationTask
    }

    // Check if a SurveyResult is reviewble, for the specified patient.
    isPatientSurveyResultNeedingReview(patient, surveyResult) {
        if (surveyResult.status == SurveyResult.Status.partial || surveyResult.enteredByClinician) {
            return
        }

        const owner = store.state.user.owner
        const user = store.state.user.user
        if (!user.has(User.Capability.canReviewSurveys) || !owner.hasFeatureFlag('hasReviewableSurveys')) {
            return false
        }

        const tasksConfig = owner.getTasksByType('surveyNeedsReview') || []

        for (let config of tasksConfig) {
            const surveySlugs = ClinicalMilestoneService.getContentSlugsFromSpec(config)

            if (
                surveySlugs &&
                surveySlugs.includes(surveyResult.surveySlug) &&
                config.startDate &&
                moment(surveyResult.endTime).isBefore(moment(config.startDate))
            ) {
                return false
            }
        }

        const activitySlug = surveyResult.activitySlug // is this set?
        const patientJourney = store.state.user.patientJourneys[surveyResult.patientJourneyId]
        if (!patientJourney) {
            Logging.error(`Could not find PatientJourney using id: ${surveyResult.patientJourneyId}`)

            return false
        }
        const journey = store.state.resources.journeys[patientJourney.journeySlug]
        let activity = journey.activitiesMap[activitySlug] || ActivityListService.getActivityBySlug(activitySlug)
        // HACK until practice-survey-results report includes activitySlug, or we lose it forever with the new data
        // pipeline!
        if (!activity) {
            activity = journey.activities.find(
                act => act.contentSlug == surveyResult.surveySlug && act.scheduleSlug == surveyResult.scheduleSlug
            )
            if (activity) {
                surveyResult.activitySlug = activity.slug
            }
        }
        if ((activity && activity.needsReview) || this.hasSurveyPromDeteriorationTask(surveyResult)) {
            return !this.isPatientSurveyResultReviewed(surveyResult)
        }

        return false
    }

    isPatientSurveyResultReviewed(surveyResult) {
        const patientJourney = store.state.user.patientJourneys[surveyResult.patientJourneyId]

        if (!patientJourney) {
            Logging.error(`Could not find PatientJourney using id: ${surveyResult.patientJourneyId}`)

            return false
        }
        const surveyReviewMilestones = patientJourney.getMilestonesOfType(Milestone.Type.surveyReview)

        return (
            surveyReviewMilestones &&
            surveyReviewMilestones.some(surveyReview => surveyReview.resultPrmId == surveyResult.resultPrmId)
        )
    }

    // Return the SurveyReviewMilestone for the patient SurveyResult, or undefined.
    getPatientSurveyResultReviewMilestone(patient, surveyResult) {
        const owner = store.state.user.owner
        const user = store.state.user.user
        if (!user.has(User.Capability.canReviewSurveys) || !owner.hasFeatureFlag('hasReviewableSurveys')) {
            return
        }
        // Do we have a SurveyReviewMilestone for this result?
        const patientJourney = store.state.user.patientJourneys[surveyResult.patientJourneyId]
        if (!patientJourney) {
            Logging.error(`Could not find PatientJourney using id: ${surveyResult.patientJourneyId}`)

            return
        }
        const surveyReviewMilestones = patientJourney.getMilestonesOfType(Milestone.Type.surveyReview)
        const milestone = surveyReviewMilestones.find(milestone => milestone.resultPrmId == surveyResult.resultPrmId)

        return milestone
    }

    // Get reviewed text, or undefined, for a Patient and SurveyResult.
    getPatientSurveyResultReviewedText(patient, surveyResult) {
        if (surveyResult) {
            const milestone = this.getPatientSurveyResultReviewMilestone(patient, surveyResult)
            if (milestone && milestone.moment && milestone.reviewerId) {
                const reviewedBy = ClinicalMilestoneService.getMilestoneReviewerName(milestone)

                return Locale.getLanguageItem(
                    milestone.isCancelled ? 'clinicalMilestoneCancelled' : 'clinicalMilestoneReviewed',
                    [reviewedBy, milestone.moment.format(Utils.readableDateFormat)]
                )
            }
        }
    }

    getPreviousSurveyResult(patientJourney, surveyResult) {
        const surveyResults = patientJourney.surveyResults.filter(
            result => result.surveySlug == surveyResult.surveySlug && result.endTime < surveyResult.endTime
        )

        if (surveyResults.length > 0) {
            const orderedSurveys = _.orderBy(surveyResults, ['endTime'], ['desc'])

            return orderedSurveys[0]
        }
    }

    /**
     * Get all PatientJourney ScheduleEvents "matching" a specified Activity, i.e. on activity.slug
     */
    getPatientJourneyScheduleEventsMatchingActivity(patientJourney, activity) {
        const scheduleEvents = (patientJourney.scheduleEvents || []).filter(
            event => event.activitySlug == activity.slug
        )

        return scheduleEvents
    }

    /**
     * Get all PatientJourney SurveyResults "matching" a specified Activity.
     * We use the same matching logic as the mobile app:
     * - SurveyResult start OR end time falls within the activity schedule window
     * We must also respect Activity.group results.
     *
     * We rely on the previous evaluation of ScheduleEvents, which already hold surveyResults.
     */
    getPatientJourneySurveyResultsMatchingActivity(patientJourney, activity, status = 'complete') {
        const scheduleEvents = this.getPatientJourneyScheduleEventsMatchingActivity(patientJourney, activity)
        if (scheduleEvents.length < 0) {
            return []
        }

        const matchingResults = new Set()
        for (const event of scheduleEvents) {
            if (event && event.surveyResults) {
                for (const result of event.surveyResults) {
                    if (result.status == status && result.isValid) {
                        matchingResults.add(result)
                    }
                }
            }
        }

        return [...matchingResults]
    }

    /**
     * Get all PatientJourney SurveyResults "matching" a specified Activity.
     * returning a map of status to array of matching results
     */
    getPatientJourneySurveyResultActivityStatusMap(patientJourney, activity) {
        const scheduleEvents = this.getPatientJourneyScheduleEventsMatchingActivity(patientJourney, activity)
        const matchingResults = {}

        if (scheduleEvents.length < 0) {
            return matchingResults
        }

        for (const event of scheduleEvents) {
            if (event && event.surveyResults) {
                for (const result of event.surveyResults) {
                    if (!matchingResults.hasOwnProperty(result.status)) {
                        matchingResults[result.status] = []
                    }
                    matchingResults[result.status].push(result)
                }
            }
        }

        return matchingResults
    }

    /**
     * Get user object from the SurveyResult.createdById field - this can either be a patient or clinician
     */
    getCreatedByUserObject(surveyResult, user, patient) {
        if (!surveyResult?.createdById) {
            return
        }

        if (user?.personaId == surveyResult.createdById) {
            return user
        }

        if (patient?.personaId == surveyResult.createdById) {
            return patient
        }

        return Object.values(store.state.user.users).find(user => user.personaId == surveyResult.createdById)
    }

    getSurveyResultEnteredByText(surveyResult, enteredBy) {
        if (!enteredBy) {
            return ''
        }

        if (surveyResult.status != SurveyResult.Status.partial) {
            return
        }

        return Locale.getLanguageItem('clinicalMilestonePartialResult', [
            enteredBy.fullName,
            moment(surveyResult.startTime).format(Utils.readableDateFormat)
        ])
    }

    getSurveyResultStatus(surveyResult, clinicianCompletableSurveys, isEnteredByUser) {
        if (surveyResult.status != SurveyResult.Status.partial) {
            return ClinicalMilestone.Status.complete
        }

        return !clinicianCompletableSurveys || !isEnteredByUser
            ? ClinicalMilestone.Status.pending
            : ClinicalMilestone.Status.pendingClinicianCompletable
    }

    getSurveyResultStatusIconLeft(surveyResult) {
        const survey = store.state.content.surveys[surveyResult.surveySlug]

        if (survey?.tags.includes('pain-survey') && surveyResult?.score != null) {
            return this.getSmileIconFromScore(surveyResult.score, survey.maxScore)
        }

        return ClinicalMilestone.Icon.survey
    }

    /**
     * Returns the smile icon (0-10) based on the score, scaled to the max score
     */
    getSmileIconFromScore(score, maxScore) {
        if (!score || score < 0 || !maxScore || maxScore == 0) {
            return 'smile-00.svg'
        }

        const ratio = score / maxScore
        let scaledScore = Math.round(ratio * 10)

        scaledScore = Math.min(scaledScore, 10)

        return `smile-${scaledScore.toString().padStart(2, '0')}.svg`
    }

    getSurveyResultStatusIconRight(surveyResult) {
        if (surveyResult.status != SurveyResult.Status.partial) {
            return ClinicalMilestone.Icon.complete
        }

        return ClinicalMilestone.Icon.partial
    }

    /*
     ** Get the milestone status object with status, text and icon. Falls back to complete
     */
    getSurveyResultMilestoneStatusObject(user, patient, surveyResult, clinicianCompletableSurveys) {
        const enteredBy = this.getCreatedByUserObject(surveyResult, user, patient)
        const isEnteredByUser = enteredBy?.personaId == user.personaId

        const rightText = this.getSurveyResultEnteredByText(surveyResult, enteredBy)
        const status = this.getSurveyResultStatus(surveyResult, clinicianCompletableSurveys, isEnteredByUser)
        const leftIcon = this.getSurveyResultStatusIconLeft(surveyResult)
        const rightIcon = this.getSurveyResultStatusIconRight(surveyResult)

        return {
            enteredBy,
            rightText,
            status,
            leftIcon,
            rightIcon
        }
    }
}

export default new SurveyResultsService()
