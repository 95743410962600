import _ from 'lodash'
import ActivityListService from '@serv/ActivityListService'
import Analytics from '@serv/Analytics'
import ClinicalMilestone from '@model/ClinicalMilestone'
import Config from '@serv/Config'
import ContentService from '@serv/ContentService'
import Locale from '@serv/Locale'
import Logging from '@serv/Logging'
import Milestone from '@model/Milestone'
import moment from 'moment'
import { nanoid } from 'nanoid/non-secure'
import PatientService from '@serv/PatientService'
import PeriodMilestone from '@model/PeriodMilestone'
import ReferralMilestone from '@model/ReferralMilestone'
import ResourceService from '@serv/ResourceService'
import RtmPeriodReviewMilestone from '@model/RtmPeriodReviewMilestone'
import Schedule from '@model/Schedule'
import ScheduleEvent from '@model/ScheduleEvent'
import Storage from '@serv/Storage'
import store from '@src/store/index'
import StringHelper from '@serv/StringHelper'
import SurveyResult from '@model/SurveyResult'
import SurveyResultsService from '@serv/SurveyResultsService'
import TaskService from '@serv/TaskService'
import { useFeatureFlags } from '@composables/state/useFeatureFlags'
import User from '@model/User'
import Utils from '@serv/Utils'

/**
 * Functions for managing ClinicalMilestones.
 */
class ClinicalMilestoneService {
    get isLoggingVerbose() {
        return Storage.get('logClinicalMilestoneService')
    }

    /**
     * For the given team lead, journey and owner, get the appropriate list of ClinicalMilestoneSpecs:
     * - Check if lead defines keyValues.clinicalMilestoneSpecs (with a matching journeySlugMask) ELSE
     * - Check if Journey itself defines ClinicalMilestoneSpecs ELSE
     * - Check if Owner defines ClinicalMilestoneSpecs (with a matching journeySlugMask) ELSE
     * - Return empty array
     */
    getClinicalMilestoneSpecsForLeadJourneyAndOwner(lead, journey, owner) {
        // Try Lead
        let configSpec
        let specs = []
        if (lead) {
            const leadSpecs = (lead.keyValues || {}).clinicalMilestoneSpecs || []
            configSpec = leadSpecs.find(spec => spec.config)
            if (configSpec) {
                configSpec.config.type = ClinicalMilestone.Type.config
                specs.push(configSpec.config)
            }
            for (const spec of leadSpecs) {
                if (!spec.journeySlugMask) {
                    continue
                }
                if (journey.matchesSlugMask(spec.journeySlugMask)) {
                    specs = [...specs, ...spec.specs]

                    return specs
                }
            }
        }
        // Try Journey
        if (journey) {
            const journeySpecs = (journey.keyValues || {}).clinicalMilestoneSpecs
            if (journeySpecs) {
                configSpec = journeySpecs.find(spec => spec.config)
                if (configSpec) {
                    configSpec.config.type = ClinicalMilestone.Type.config
                    specs.push(configSpec.config)
                }

                return [...specs, ...journeySpecs]
            }
        }
        // Try Owner
        if (owner) {
            const ownerSpecs = ((owner.keyValues || {}).dash || {}).clinicalMilestoneSpecs || []
            configSpec = ownerSpecs.find(spec => spec.config)
            if (configSpec) {
                configSpec.config.type = ClinicalMilestone.Type.config
                specs.push(configSpec.config)
            }
            for (const spec of ownerSpecs) {
                if (!spec.journeySlugMask) {
                    continue
                }
                if (journey.matchesSlugMask(spec.journeySlugMask)) {
                    return [...specs, ...spec.specs]
                }
            }
        }

        return []
    }

    /**
     * From an object that may specify 'contentSlug', 'contentSlug' or 'contentTags', get a list of matching contentSlugs (which may be empty).
     * Otherwise return undefined.
     */
    getContentSlugsFromSpec(spec) {
        if (spec.contentSlugs) {
            return spec.contentSlugs
        }

        if (spec.contentSlug) {
            return [spec.contentSlug]
        }

        if (spec.contentTag) {
            return ContentService.getSurveysWithTag(spec.contentTag).map(survey => survey.slug)
        }

        return undefined
    }

    /**
     * For a PJ, calculate the array of ClinicalMilestones for the chart, as follows:
     * - For each PJM on the global journey (e.g. invitation, registration) add a row
     *
     * - Consider whether to process a single PJ or all PJs (pathwayJourneys)
     * - For each PJ:
     *  - For each spec of type 'date'
     *      - For each matching PJM on the PJ, add a row
     *  - For each spec of type 'survey'
     *      - Find all matching survey results (by contentSlug) on the PJ
     *      - Add a row for each, and build a list of all PJ ScheduleEvents the results match
     *      - For any remaining ScheduleEvent objects that match the spec, add a row for "expecting result"
     *
     * If patientJourney is undefined, or the specs include a "config spec" with "allPatientJourneys" then we add rows
     * for ALL patient PJs.
     */
    getClinicalMilestonesResolvedForPatientJourney(patient, inPatientJourney) {
        if (this.isLoggingVerbose) {
            Logging.log(`Calculating ClinicalMilestone rows for patient ${patient.personaId}...`)
        }

        let clinicalMilestones = []
        const user = store.state.user.user
        const owner = store.state.user.owner
        const patientPageTabCptConfig = owner.getPatientPageTabCptConfig('TabCptResultsClinicalMilestones')

        // Use single PJ for specs related to global stuff
        let journey = store.state.resources.journeys[(inPatientJourney || patient.firstJourney).journeySlug]
        let specs = this.getClinicalMilestoneSpecsForLeadJourneyAndOwner(user, journey, owner)

        // Get config object (if any)
        const config = specs.find(spec => spec.type == ClinicalMilestone.Type.config) || {}
        if (this.isLoggingVerbose) {
            Logging.log(`Config object: ${JSON.stringify(config)}`)
        }

        if (patientPageTabCptConfig?.forceRegMilestone) {
            this.forceAddRegistrationMilestone(patient)
        }

        // Global PJMs
        const globalMilestoneSlugs = ['invitation', 'registration']
        globalMilestoneSlugs.forEach(milestoneSlug => {
            if (specs.find(spec => spec.type == ClinicalMilestone.Type.date && spec.milestone == milestoneSlug)) {
                const cm = new ClinicalMilestone({
                    type: ClinicalMilestone.Type.date,
                    milestoneSlug: milestoneSlug
                })
                if (this.isLoggingVerbose) {
                    Logging.log(`Adding row: Global PJM with slug ${milestoneSlug}`)
                }
                this._resolvePatientJourneyClinicalMilestoneOfType_date(
                    cm,
                    patient,
                    patient.globalJourney,
                    clinicalMilestones
                )
            }
        })

        // Pathway(s)
        const ignoreTypes = ['activityData', ClinicalMilestone.Type.config]
        let patientJourneys =
            inPatientJourney && !config.allPatientJourneys ? [inPatientJourney] : patient.pathwayJourneys
        for (const patientJourney of patientJourneys) {
            if (this.isLoggingVerbose) {
                Logging.log(`For PatientJourney ${patientJourney.patientJourneyId}:`)
            }
            journey = store.state.resources.journeys[patientJourney.journeySlug]
            const activitiesMap = PatientService.getPatientJourneyActivitiesMap(patient, patientJourney)
            for (const spec of specs) {
                // Stuff to ignore
                if (ignoreTypes.includes(spec.type) || globalMilestoneSlugs.includes(spec.milestone)) {
                    continue
                }
                // Dates (PJMs)
                if (spec.type == ClinicalMilestone.Type.date && !globalMilestoneSlugs.includes(spec.milestoneSlug)) {
                    const cm = new ClinicalMilestone({
                        type: ClinicalMilestone.Type.date,
                        milestoneSlug: spec.milestone
                    })
                    this._resolvePatientJourneyClinicalMilestoneOfType_date(
                        cm,
                        patient,
                        patientJourney,
                        clinicalMilestones
                    )
                    continue
                }
                // Survey results
                if (spec.type == ClinicalMilestone.Type.survey) {
                    const contentSlugs = this.getContentSlugsFromSpec(spec)
                        .filter(contentSlug => {
                            if (store.state.content.surveys[contentSlug]) {
                                return contentSlug
                            }
                            Logging.warn(`Could not find content: ${contentSlug}`)
                        })
                        .sort()
                    if (this.isLoggingVerbose) {
                        Logging.log(
                            `From spec ${JSON.stringify(spec)}, adding rows for surveys with slugs: ${contentSlugs}`
                        )
                    }

                    const matchedScheduleEvents = new Set()
                    const matchedResults = patientJourney.surveyResults.filter(
                        result => contentSlugs.includes(result.surveySlug) && result.isValid
                    )

                    if (this.isLoggingVerbose) {
                        Logging.log(`Found ${matchedResults.length} matching SurveyResults`)
                    }
                    for (const surveyResult of matchedResults) {
                        this._resolvePatientJourneyObjectOfType_survey(
                            surveyResult, // function allows passing in SurveyResult
                            patient,
                            patientJourney,
                            clinicalMilestones,
                            spec,
                            config
                        )
                        /**
                         * Find matching ScheduleEvent for Activity.
                         */
                        patientJourney.scheduleEvents.forEach(event => {
                            const matches = surveyResult.matches({
                                event,
                                patientJourney: patientJourney,
                                matchJourney: false
                            })

                            if (matches) {
                                matchedScheduleEvents.add(event)
                            }
                        })
                    }
                    // Filtering out non-qualifying ScheduleEvents
                    const repeatMinDays = config.includeRepeatingAtLeastDays || 90

                    const scheduleEvents = patientJourney.scheduleEvents.filter(event => {
                        const activity =
                            activitiesMap[event.activitySlug] ||
                            ActivityListService.getActivityBySlug(event.activitySlug)
                        if (!activity) {
                            return false
                        }
                        // Discount repeating schedules of width < 90
                        const schedule = Schedule.get(activity.scheduleSlug)

                        return !(schedule.isRepeating && schedule.interval < repeatMinDays)
                    })
                    // Create rows for any qualifying ScheduleEvents not matched to results
                    for (const event of scheduleEvents) {
                        if (matchedScheduleEvents.has(event)) {
                            continue
                        }

                        const activity =
                            activitiesMap[event.activitySlug] ||
                            ActivityListService.getActivityBySlug(event.activitySlug)
                        if (activity && contentSlugs.includes(activity.contentSlug)) {
                            // Only resolve this row if we do not have SurveyResults within it
                            // This may be the case for partial surveys
                            if (event.surveyResults?.length <= 0) {
                                this._resolvePatientJourneyObjectOfType_survey(
                                    event, // function allows passing in ScheduleEvent
                                    patient,
                                    patientJourney,
                                    clinicalMilestones,
                                    spec,
                                    config
                                )
                            }
                        }
                    }
                    continue
                }
                // Other types
                const cm = new ClinicalMilestone({
                    type: spec.type
                })
                const typesRequiringCanReviewRtm = [
                    ClinicalMilestone.Type.rtmPeriodReview,
                    ClinicalMilestone.Type.rtmReminder,
                    ClinicalMilestone.Type.rtmPeriodEnd,
                    ClinicalMilestone.Type.rtmInsufficientStepsReview
                ]
                if (typesRequiringCanReviewRtm.includes(spec.type) && !user.has(User.Capability.canReviewRtm)) {
                    continue
                }
                const typesRequiringHcpCareNavigator = [
                    ClinicalMilestone.Type.carePeriod,
                    ClinicalMilestone.Type.bundleEndDate
                ]
                if (typesRequiringHcpCareNavigator.includes(spec.type) && user.role != User.Role.hcpCareNavigator) {
                    continue
                }
                const typesRequiringRtmAdminRole = [
                    ClinicalMilestone.Type.rtmUnregisteredReview,
                    ClinicalMilestone.Type.rtmStepsReview
                ]

                if (typesRequiringRtmAdminRole.includes(spec.type) && user.role != User.Role.adminRtm) {
                    continue
                }

                if (spec.type == ClinicalMilestone.Type.rtmPeriodReview) {
                    cm.hideExpired = !!spec.hideExpired
                    cm.hideEligibleNextMonth = !!spec.hideEligibleNextMonth
                }

                const typesWithStartDate = [
                    ClinicalMilestone.Type.rtmReminder,
                    ClinicalMilestone.Type.rtmUnregisteredReview,
                    ClinicalMilestone.Type.rtmStepsReview,
                    ClinicalMilestone.Type.rtmInsufficientStepsReview
                ]

                if (typesWithStartDate.includes(spec.type)) {
                    cm.startDate = spec.startDate
                }

                const typesWithReviewSchedules = [
                    ClinicalMilestone.Type.rtmUnregisteredReview,
                    ClinicalMilestone.Type.rtmStepsReview,
                    ClinicalMilestone.Type.rtmInsufficientStepsReview
                ]

                if (typesWithReviewSchedules.includes(spec.type)) {
                    cm.reviewSchedules = spec.reviewSchedules
                }

                const fnName = `_resolvePatientClinicalMilestoneOfType_${cm.type}`
                if (this[fnName]) {
                    this[fnName](cm, patient, patientJourney, clinicalMilestones)
                } else {
                    Logging.error(`No resolve function for ClinicalMilestone of type ${cm.type}`)
                }
            }
            // Add "insufficient data" row
            const hasRtmPeriodReviewType = specs.find(spec => spec.type == ClinicalMilestone.Type.rtmPeriodReview)
            if (user.has(User.Capability.canReviewRtm) && patient.isRegistered && hasRtmPeriodReviewType) {
                this._resolvePatientClinicalMilestoneOfType_rtmPeriodInsufficient(
                    patient,
                    patientJourney,
                    clinicalMilestones
                )
            }
            // Next PJ...
        }

        // Sort reverse chrono
        const unsortable = clinicalMilestones.filter(cm => cm.moment == undefined)

        // For now, milestones with undefined moments are placed "in the future", i.e. at end of list
        clinicalMilestones = clinicalMilestones.filter(cm => cm.moment)
        clinicalMilestones.forEach(cm => {
            cm.addTypeMomentOffset()
        })
        clinicalMilestones.sort((milestoneA, milestoneB) => {
            return milestoneB.moment - milestoneA.moment
        })
        clinicalMilestones = [...clinicalMilestones, ...unsortable]

        // Vue by default uses an "in-place patch" strategy, to track each node's identity in v-for we need to provide
        // unique key
        clinicalMilestones.forEach(milestone => {
            milestone.uuid = nanoid()
        })

        return clinicalMilestones
    }

    forceAddRegistrationMilestone(patient) {
        if (!patient.isRegistered && !patient.globalJourney.getMilestoneOfSlug('registration')) {
            const regMilestone = new Milestone({
                type: Milestone.Type.default,
                slug: 'registration',
                date: moment().format(Utils.serialisedDateFormat)
            })

            patient.globalJourney.addMilestone(regMilestone, true)
        }
    }

    getClinicianCompletableSurveysConfig({ user, owner }) {
        const patientPageTabCptConfig = owner.getPatientPageTabCptConfig('TabCptResultsClinicalMilestones')
        const clinicianCompletableSurveysConfig = patientPageTabCptConfig?.clinicianCompletableSurveys

        if (!clinicianCompletableSurveysConfig) {
            return false
        }

        if (typeof clinicianCompletableSurveysConfig === 'boolean') {
            return clinicianCompletableSurveysConfig
        }

        if (clinicianCompletableSurveysConfig?.rolesEnabled) {
            const roleConfig = clinicianCompletableSurveysConfig?.rolesEnabled?.[user?.role]

            if (roleConfig === 'all') {
                return true
            }

            return clinicianCompletableSurveysConfig.rolesEnabled?.[user?.role]?.surveys
        }

        return false
    }

    canClinicianCompleteSurvey({ config, contentSlug }) {
        let canClinicianCompleteSurvey

        if (typeof config === 'boolean') {
            canClinicianCompleteSurvey = config
        } else if (Array.isArray(config)) {
            canClinicianCompleteSurvey = config.includes(contentSlug)
        }

        return !!canClinicianCompleteSurvey
    }

    _resolvePatientJourneyClinicalMilestoneOfType_date(inMilestone, patient, patientJourney, clinicalMilestones) {
        let milestones = patientJourney.getMilestonesOfSlug(inMilestone.milestoneSlug)

        if (!patient.isRegistered) {
            milestones = milestones.filter(milestone => milestone.slug !== 'registration')
        }

        const nowMoment = moment()
        milestones.forEach(milestone => {
            const clinicalMilestone = _.clone(inMilestone)
            clinicalMilestones.push(clinicalMilestone)
            clinicalMilestone.moment = milestone.moment
            if (this.isLoggingVerbose) {
                Logging.log(
                    `Adding row: PJM slug ${milestone.slug}, subtype ${
                        milestone.subtype
                    }, datetime ${clinicalMilestone.moment.format(Utils.serialisedDateTimeFormat)}`
                )
            }
            if (clinicalMilestone.moment.isBefore(nowMoment)) {
                clinicalMilestone.status = ClinicalMilestone.Status.complete
                clinicalMilestone.rightIconFilename = undefined // no icon
            } else {
                clinicalMilestone.status = ClinicalMilestone.Status.future
                clinicalMilestone.rightIconFilename = undefined // no icon
            }

            // leftIconFilename, leftText
            clinicalMilestone.milestone = milestone
            clinicalMilestone.milestone.patientJourney = patientJourney
            if (inMilestone.milestoneSlug == 'referral') {
                // Legacy referral milestone
                if (!milestone.status && milestone.referredToId) {
                    clinicalMilestone.leftIconFilename = ClinicalMilestone.Icon.hospital
                    const user = store.state.user.users[milestone.referredToId]
                    if (this.isLoggingVerbose) {
                        Logging.log(`Adding row: Referral to user ${milestone.referredToId}`)
                    }
                    if (!user) {
                        Logging.error(
                            `Could not find referredTo user persona ${milestone.referredToId} for patient ${patient.personaId}`
                        )
                        clinicalMilestone.leftText = Locale.getLanguageItem('clinicalMilestoneRowReferralInfo', [
                            '',
                            ''
                        ])
                    } else {
                        clinicalMilestone.leftText = Locale.getLanguageItem('clinicalMilestoneRowReferralInfo', [
                            user.titledLastName,
                            user.personaLabel
                        ])
                    }
                } else {
                    clinicalMilestone.leftIconFilename = ClinicalMilestone.Icon.reminder
                    clinicalMilestone.leftText = this._getReferralMilestoneLeftText(milestone)
                    clinicalMilestone.isClickable = !(!milestone.status && milestone.referredToProviderSlug)
                    clinicalMilestone.isNeedingReview = PatientService.isReferralNeedingReview(
                        patientJourney,
                        milestone
                    )
                    clinicalMilestone.isTask = TaskService.isReferralTask(patientJourney, milestone)
                    clinicalMilestone.rightIconFilename = ClinicalMilestone.Icon.pending
                    clinicalMilestone.status = ClinicalMilestone.Status.pending

                    if (
                        ReferralMilestone.Status.accepted == milestone.status &&
                        !!milestone.acceptedDate &&
                        milestone.acceptedById
                    ) {
                        const acceptedBy = store.state.user.users[milestone.acceptedById]?.titledFullName || ''
                        clinicalMilestone.rightText = Locale.getLanguageItem('clinicalMilestoneReferralAccepted', [
                            acceptedBy,
                            moment(milestone.reviewedDate).format(Utils.readableDateFormat)
                        ])
                    }

                    if (
                        [ReferralMilestone.Status.accepted, ReferralMilestone.Status.rejected].includes(
                            milestone.status
                        )
                    ) {
                        clinicalMilestone.status = ClinicalMilestone.Status.complete
                        clinicalMilestone.rightIconFilename = undefined
                    }

                    if (milestone.status == ReferralMilestone.Status.discharged) {
                        clinicalMilestone.status = ClinicalMilestone.Status.complete
                        clinicalMilestone.rightIconFilename = undefined

                        clinicalMilestone.moment = moment(milestone.endDate)
                    }
                }
            } else {
                // Any other milestone
                clinicalMilestone.leftIconFilename = ClinicalMilestone.Icon.hospital

                const milestoneTitle = milestone.subtype
                    ? Locale.getLanguageItemForModelEnum(milestone.subtypeKey, milestone.subtype)
                    : Locale.getLanguageItemForModelEnum('milestone', inMilestone.milestoneSlug)
                let text = Locale.getLanguageItem(
                    milestone.type == 'appointment'
                        ? 'clinicalMilestoneRowAppointmentDate'
                        : 'clinicalMilestoneRowDate',
                    [milestoneTitle]
                )
                if (milestone.time) {
                    text += ` (${moment(milestone.time, 'HH:mm').format('HH:mm')})`
                }
                const summaryText = patientJourney.summaryText
                // If multiple journeys, add a suffix with the journey name and primary milestone date
                if (patient.numPatientJourneys > 1 && summaryText) {
                    clinicalMilestone.leftText = `${text} - ${summaryText}`
                } else {
                    clinicalMilestone.leftText = text
                }
            }
        })
    }

    _getReferralMilestoneLeftText(milestone) {
        const referredToProviderTitle = store.state.resources.providers[milestone.referredToProviderSlug]?.title || ''

        if (ReferralMilestone.Status.discharged == milestone.status) {
            return Locale.getLanguageItem('clinicalMilestoneReferralCompleted', [referredToProviderTitle])
        }

        if (ReferralMilestone.Status.rejected == milestone.status) {
            return Locale.getLanguageItem('clinicalMilestoneReferralRejected', [referredToProviderTitle])
        }

        if (
            (!milestone.status && milestone.referredToProviderSlug) ||
            [ReferralMilestone.Status.offered, ReferralMilestone.Status.accepted].includes(milestone.status)
        ) {
            const referredFromProviderTitle =
                store.state.resources.providers[milestone.referredFromProviderSlug]?.title || ''
            const referredFrom = store.state.user.users[milestone.referredFromId]?.fullName || ''

            return Locale.getLanguageItem('clinicalMilestoneReferralOffered', [
                referredToProviderTitle,
                referredFrom,
                referredFromProviderTitle
            ])
        }
    }

    _onClickPatientClinicalMilestoneOfType_date(clinicalMilestone, patient) {
        if (clinicalMilestone.milestoneSlug == 'referral' && !!clinicalMilestone.milestone.status) {
            const patientTeamProvider = PatientService.getPatientJourneyTeamProvider(
                clinicalMilestone.milestone.patientJourney
            )
            const canReferToProviderSlugs = patientTeamProvider?.keyValues.canReferToProviderSlugs

            store.commit('popup/setConfig', {
                canReferToProviderSlugs,
                patientTeamProvider,
                patient: patient,
                milestone: clinicalMilestone.milestone,
                clinicalMilestone: clinicalMilestone
            })

            if (canReferToProviderSlugs) {
                store.commit('popup/setClass', 'PopupReferralToProviderReview')
            } else {
                store.commit('popup/setClass', 'PopupReferralReview')
            }

            Analytics.sendEvent('referralNeedsReview', {
                patientId: patient.personaId,
                patientJourneyMilestoneId: clinicalMilestone.milestone.id,
                status: clinicalMilestone.milestone.status
            })
        }
    }

    _resolvePatientClinicalMilestoneOfType_rtmReview(inMilestone, patient, patientJourney, clinicalMilestones) {
        const rtmReviewMilestones = patient
            .getMilestonesOfSlug('rtm-period-review')
            .filter(milestone => !milestone.rtmCode)

        rtmReviewMilestones.forEach(milestone => {
            const clinicalMilestone = _.clone(inMilestone)
            clinicalMilestones.push(clinicalMilestone)
            clinicalMilestone.moment = moment(milestone.reviewedDate)
            clinicalMilestone.milestone = milestone
            clinicalMilestone.isClickable = true
            clinicalMilestone.leftIconFilename = ClinicalMilestone.Icon.survey
            clinicalMilestone.leftText = Locale.getLanguageItem('clinicalMilestoneRtmReview')
            clinicalMilestone.isNeedingReview = false
            clinicalMilestone.status = ClinicalMilestone.Status.complete
            clinicalMilestone.rightIconFilename = ClinicalMilestone.Icon.complete
        })
    }

    _resolvePatientClinicalMilestoneOfType_rtmPeriodReview(inMilestone, patient, patientJourney, clinicalMilestones) {
        // If journey is not eligible for RTM don't display RTM period review PJM
        const journey = store.state.resources.journeys[patientJourney.journeySlug]
        if (!journey.hasRtm) {
            return
        }

        // RTM review milestones without RTM code
        this._resolvePatientClinicalMilestoneOfType_rtmReview(inMilestone, patient, patientJourney, clinicalMilestones)

        // RTM period review milestones with RTM code 98980
        // With feature flag RTM_PERIOD_REFACTOR, this applies to RTM review milestones with RTM code 98977
        const { FeatureFlag, getFeatureFlag } = useFeatureFlags()
        const rtm98977MilestoneCode = getFeatureFlag(FeatureFlag.rtmPeriodRefactor)
            ? RtmPeriodReviewMilestone.Code.rtm_98977
            : RtmPeriodReviewMilestone.Code.rtm_98980

        let rtmPeriodReviewMilestones = PatientService.getValidPatientRtmPeriodReviewMilestonesByCode(
            patient,
            rtm98977MilestoneCode
        )

        rtmPeriodReviewMilestones.sort((a, b) => b.moment - a.moment)
        const hasReviewedRtmPeriodThisMonth = PatientService.hasReviewedRtmPeriodInCurrentMonth(patient)

        rtmPeriodReviewMilestones.forEach((milestone, index) => {
            const distinctDaysPeriodStart = Utils.getPeriodStartDateBetweenDates(milestone.date, milestone.endDate)
            const distinctDays = PatientService.getPatientDistinctDataDaysBetweenDates(
                patient,
                distinctDaysPeriodStart,
                milestone.endDate
            )

            /*
             * Display RTM milestones with insufficient data when:
             * - it's local or QA env
             * OR
             * - already has been reviewed
             * - needs review and has at least 16 distinct days of data
             */
            if (
                Config.isQaEnv ||
                milestone.reviewerId ||
                (!milestone.reviewerId && distinctDays.numTotalDistinctDays > 15)
            ) {
                const clinicalMilestone = _.clone(inMilestone)
                clinicalMilestones.push(clinicalMilestone)
                clinicalMilestone.milestone = milestone
                clinicalMilestone.isClickable = true
                clinicalMilestone.leftIconFilename = ClinicalMilestone.Icon.survey
                clinicalMilestone.leftText = Locale.getLanguageItem(
                    milestone.reviewerId && milestone.isLegacy
                        ? 'clinicalMilestoneRtmDataReview'
                        : 'clinicalMilestoneRtmDataReviewPermitted',
                    [
                        moment(milestone.date).format(Utils.readableDateFormat),
                        moment(milestone.endDate).format(Utils.readableDateFormat),
                        Config.isQaEnv && distinctDays.numTotalDistinctDays < 16
                            ? Locale.getLanguageItem('clinicalMilestoneRtmDataReviewQaOnly')
                            : ''
                    ]
                )
                this.getMilestoneMoment(clinicalMilestone, milestone)

                const isTask = TaskService.isRtmPeriodReviewTask(patientJourney, milestone)

                clinicalMilestone.isNeedingReview = isTask
                clinicalMilestone.status = ClinicalMilestone.Status.complete
                clinicalMilestone.rightIconFilename = ClinicalMilestone.Icon.complete
                clinicalMilestone.isTask = isTask

                if (!!milestone.reviewedDate && milestone.reviewerId) {
                    const reviewedBy = this.getMilestoneReviewerName(milestone)
                    // Reviewed
                    clinicalMilestone.rightText = Locale.getLanguageItem('clinicalMilestoneReviewed', [
                        reviewedBy,
                        moment(milestone.reviewedDate).format(Utils.readableDateFormat)
                    ])
                } else if (index == 0 && !milestone.reviewedDate && !hasReviewedRtmPeriodThisMonth) {
                    // Eligible for review (this month)
                } else if (!inMilestone.hideEligibleNextMonth && !inMilestone.hideExpired) {
                    // 27.10.2023: Remove Expired and Eligible review next month logic and display all 98977 RTM period review milestones
                    // in the milestones chart.
                    const recentMilestone = PatientService.getPatientRecentValidRtmPeriodReviewMilestone(patient)
                    if (milestone.id == recentMilestone.id && hasReviewedRtmPeriodThisMonth) {
                        // Eligible next month
                        clinicalMilestone.style = ClinicalMilestone.Style.warning
                        clinicalMilestone.rightText = Locale.getLanguageItem(
                            'clinicalMilestoneRtmPeriodReviewEligibleNextMonth'
                        )
                        clinicalMilestone.isHidden = inMilestone.hideEligibleNextMonth
                    } else {
                        // Expired
                        clinicalMilestone.style = ClinicalMilestone.Style.warning
                        clinicalMilestone.rightText = Locale.getLanguageItem('clinicalMilestoneRtmPeriodReviewExpired')
                        clinicalMilestone.isHidden = inMilestone.hideExpired
                    }
                }
            } else {
                Logging.error(`Patient ${patient.personaId} has RTM PJM with insufficient data: ${milestone.id}`)
            }
        })
    }
    _onClickPatientClinicalMilestoneOfType_rtmPeriodReview(clinicalMilestone, patient) {
        store.commit('popup/setConfig', {
            patient: patient,
            milestone: clinicalMilestone.milestone,
            clinicalMilestone: clinicalMilestone
        })
        store.commit('popup/setClass', 'PopupRtmDataReview')

        if (clinicalMilestone.milestone.rtmCode) {
            Analytics.sendEvent('rtmPeriodNeedsReview', {
                patientId: patient.personaId,
                patientJourneyMilestoneId: clinicalMilestone.milestone.id,
                rtmCode: clinicalMilestone.milestone.rtmCode
            })
        }
    }

    // Returns true if there aren't enough days remaining for the patient to reach sixteen distinct days of data
    _checkIfNotEnoughDaysToReachSixteenDistinctDaysOfData(patient, startDate, periodEndDate) {
        const periodStartDate = Utils.getPeriodStartDateBetweenDates(startDate, periodEndDate)

        const distinctDays = PatientService.getPatientDistinctDataDaysBetweenDates(
            patient,
            periodStartDate,
            periodEndDate
        )

        // When there aren't enough days between now and the discharge date to reach 16 distinct days of data for the window, don't display the row
        return 16 - distinctDays.numTotalDistinctDays > moment(periodEndDate).diff(moment(), 'days')
    }

    /**
     * Add RTM period insufficient milestone:
     *      - Journey is eligible for RTM and operation date is set (for RTM schedule window start date)
     *      AND
     *      - Today is before RTM discharge date - rtm-discharge PJM exists
     *          and patient doesn't have any RTM period review milestones
     *          and there are at least 16 more days before RTM discharge date
     *          and there are enough days before RTM discharge date to reach 16 days of distinct data
     *      OR
     *      - Today is before RTM discharge date - rtm-discharge PJM exists
     *          and patient has most recent RTM period review
     *          and today is beyond the patient's most recent RTM period review endDate
     *          and there are at least 16 more days before RTM discharge date
     *          and there are enough days before RTM discharge date to reach 16 days of distinct data
     *      OR
     *      - Patient has not been discharged from RTM
     *          and patient doesn't have any RTM period review milestones
     *          and today is beyond RTM schedule start date
     *          and today is before RTM window review date
     *      OR
     *      - Patient has not been discharged from RTM
     *          and patient has most recent RTM period review
     *          and today is beyond the patient's most recent RTM period review endDate
     *          and today is before RTM window review date
     */
    _resolvePatientClinicalMilestoneOfType_rtmPeriodInsufficient(patient, patientJourney, clinicalMilestones) {
        const journey = store.state.resources.journeys[patientJourney.journeySlug]
        if (!journey.hasRtm) {
            return
        }

        const nowMoment = moment()
        const today = nowMoment.format(Utils.serialisedDateFormat)

        const rtmStartScheduleMoments = PatientService.getPatientScheduleStartEndMoments(
            patient,
            journey.rtmStartSchedule
        )
        if (!rtmStartScheduleMoments[0]) {
            return
        }

        const rtmBundleStartDate = rtmStartScheduleMoments[0].format(Utils.serialisedDateFormat)

        if (today < rtmBundleStartDate) {
            return
        }

        const rtmWindowEndMoment = PatientService.getPatientRtmWindowEndMoment(
            patient,
            patientJourney,
            journey.rtmEndSchedule
        )

        if (rtmWindowEndMoment && today >= rtmWindowEndMoment.format(Utils.serialisedDateFormat)) {
            return
        }

        const rtmDischargeMilestone = patientJourney.getMilestoneOfSlug('rtm-discharge')

        if (rtmDischargeMilestone && today >= rtmDischargeMilestone.date) {
            return
        }

        const recentRtmPeriodReview = PatientService.getPatientRecentValidRtmPeriodReviewMilestone(patient)

        let periodStartDate

        if (!recentRtmPeriodReview) {
            periodStartDate = rtmBundleStartDate

            if (
                rtmDischargeMilestone &&
                this._checkIfNotEnoughDaysToReachSixteenDistinctDaysOfData(
                    patient,
                    rtmBundleStartDate,
                    rtmDischargeMilestone.date
                )
            ) {
                return
            }
        } else {
            const newPeriodStartMoment = moment(recentRtmPeriodReview.endDate).add(1, 'day')

            if (rtmDischargeMilestone) {
                // When discharge date is set and < 16 days between RTM period review milestone end date and RTM discharge date then don't display row
                if (moment(rtmDischargeMilestone.date).diff(newPeriodStartMoment, 'days') < 16) {
                    return
                }

                if (
                    this._checkIfNotEnoughDaysToReachSixteenDistinctDaysOfData(
                        patient,
                        newPeriodStartMoment.format(Utils.serialisedDateFormat),
                        rtmDischargeMilestone.date
                    )
                ) {
                    return
                }
            }

            if (today > newPeriodStartMoment.format(Utils.serialisedDateFormat)) {
                periodStartDate = newPeriodStartMoment.format(Utils.serialisedDateFormat)
            } else {
                return
            }
        }

        const clinicalMilestone = new ClinicalMilestone({
            type: ClinicalMilestone.Type.rtmPeriodInsufficient
        })

        clinicalMilestone.startDate = periodStartDate
        clinicalMilestone.endDate = today
        clinicalMilestone.moment = nowMoment

        clinicalMilestones.push(clinicalMilestone)
        clinicalMilestone.leftIconFilename = ClinicalMilestone.Icon.survey
        clinicalMilestone.leftText = Locale.getLanguageItem('clinicalMilestoneRtmInsufficientDataReview')
        const distinctDaysPeriodStart = Utils.getPeriodStartDateBetweenDates(
            clinicalMilestone.startDate,
            clinicalMilestone.endDate
        )
        const distinctDays = PatientService.getPatientDistinctDataDaysBetweenDates(
            patient,
            distinctDaysPeriodStart,
            clinicalMilestone.endDate
        )
        clinicalMilestone.rightText = Locale.getLanguageItem('clinicalMilestoneRtmInsufficientDataDays', [
            distinctDays.numTotalDistinctDays
        ])
        clinicalMilestone.style = ClinicalMilestone.Style.warning
        clinicalMilestone.rightIconFilename = ClinicalMilestone.Icon.warning
    }

    _resolvePatientClinicalMilestoneOfType_rtmPeriodEnd(inMilestone, patient, patientJourney, clinicalMilestones) {
        const journey = store.state.resources.journeys[patientJourney.journeySlug]
        if (!journey.hasRtm) {
            return
        }

        let clinicalMilestone

        const rtmDischargeMilestone = patientJourney.getMilestoneOfSlug('rtm-discharge')

        if (rtmDischargeMilestone) {
            clinicalMilestone = _.clone(inMilestone)
            clinicalMilestones.push(clinicalMilestone)
            clinicalMilestone.moment = rtmDischargeMilestone.moment
            clinicalMilestone.scheduleSlug = journey.rtmEndSchedule
            clinicalMilestone.milestone = rtmDischargeMilestone
            clinicalMilestone.leftIconFilename = ClinicalMilestone.Icon.onboarding
            clinicalMilestone.leftText = Locale.getLanguageItem('clinicalMilestoneRtmDischargedOn', [
                moment(rtmDischargeMilestone.date).format(Utils.readableDateFormat)
            ])
            clinicalMilestone.isClickable = true
        } else {
            const rtmExtendMilestones = patientJourney.getMilestonesOfSlug('rtm-extend')

            rtmExtendMilestones.forEach(milestone => {
                clinicalMilestone = _.clone(inMilestone)
                clinicalMilestones.push(clinicalMilestone)
                clinicalMilestone.moment = milestone.moment
                clinicalMilestone.scheduleSlug = journey.rtmEndSchedule
                clinicalMilestone.milestone = milestone
                clinicalMilestone.leftIconFilename = ClinicalMilestone.Icon.onboarding
                clinicalMilestone.leftText = Locale.getLanguageItem('clinicalMilestoneRtmWindowExtendedTo', [
                    moment(milestone.endDate).format(Utils.readableDateFormat)
                ])
                clinicalMilestone.isClickable = true
                clinicalMilestone.canDownloadPdf = false
            })

            // If patient has not been discharged from RTM, add milestone for RTM window review ending on RTM end schedule + RTM schedule offset
            const rtmWindowEndMoment = PatientService.getPatientRtmWindowEndMoment(
                patient,
                patientJourney,
                journey.rtmEndSchedule
            )

            if (rtmWindowEndMoment) {
                const clinicalMilestone = _.clone(inMilestone)
                clinicalMilestones.push(clinicalMilestone)
                clinicalMilestone.moment = rtmWindowEndMoment
                clinicalMilestone.scheduleSlug = journey.rtmEndSchedule
                clinicalMilestone.milestone = new PeriodMilestone({
                    date: rtmWindowEndMoment.format(Utils.serialisedDateFormat),
                    notes: null
                })
                clinicalMilestone.leftIconFilename = ClinicalMilestone.Icon.onboarding
                clinicalMilestone.leftText = Locale.getLanguageItem('clinicalMilestoneRtmWindowReview')
                if (rtmWindowEndMoment.diff(moment(), 'days') < 8) {
                    clinicalMilestone.isClickable = true
                    clinicalMilestone.isNeedingReview = true
                    clinicalMilestone.isTask = TaskService.isRtmWindowReviewTask(patientJourney)
                    clinicalMilestone.rightIconFilename = ClinicalMilestone.Icon.pending
                }
                clinicalMilestone.canDownloadPdf = false
            }
        }
    }

    _onClickPatientClinicalMilestoneOfType_rtmPeriodEnd(clinicalMilestone, patient) {
        store.commit('popup/setConfig', {
            patient: patient,
            milestone: clinicalMilestone.milestone,
            clinicalMilestone: clinicalMilestone
        })
        store.commit('popup/setClass', 'PopupRtmWindowReview')

        Analytics.sendEvent('rtmWindowReview', {
            patientId: patient.personaId,
            patientJourneyMilestoneId: clinicalMilestone.milestone.id
        })
    }

    _resolvePatientClinicalMilestoneOfType_rtmReminder(inMilestone, patient, patientJourney, clinicalMilestones) {
        const journey = store.state.resources.journeys[patientJourney.journeySlug]

        if (!journey.hasRtm) {
            return
        }

        if (!journey.rtmReminderSchedules || journey.rtmReminderSchedules.length == 0) {
            return
        }

        for (const reminderSchedule of journey.rtmReminderSchedules) {
            const schedule = Schedule.get(reminderSchedule)

            const [startMoment] = PatientService.getPatientScheduleStartEndMoments(
                patient,
                schedule.slug,
                patientJourney
            )

            if (!startMoment) {
                continue
            }

            if (inMilestone.startDate && startMoment.isBefore(moment(inMilestone.startDate))) {
                continue
            }

            const clinicalMilestone = _.clone(inMilestone)
            clinicalMilestones.push(clinicalMilestone)

            clinicalMilestone.moment = startMoment
            clinicalMilestone.leftIconFilename = ClinicalMilestone.Icon.reminder

            clinicalMilestone.leftText = Locale.getLanguageItem('clinicalMilestoneRowReviewReminder', [
                schedule.summary
            ])

            const milestone = PatientService.getPatientRtmScheduledReviewMilestone(patientJourney, schedule.slug)

            clinicalMilestone.milestone = milestone
            clinicalMilestone.scheduleSlug = schedule.slug

            if (milestone && milestone.reviewerId) {
                const reviewedBy = this.getMilestoneReviewerName(milestone)

                clinicalMilestone.rightText = Locale.getLanguageItem('clinicalMilestoneSubmitted', [
                    reviewedBy,
                    moment(milestone.date).format(Utils.readableDateFormat)
                ])
            }

            if (moment().isAfter(startMoment)) {
                clinicalMilestone.rightIconFilename = ClinicalMilestone.Icon.pending
                clinicalMilestone.isNeedingReview = !milestone
                clinicalMilestone.isClickable = true
                clinicalMilestone.isTask = TaskService.isRtmScheduledReminderReviewTask(
                    patientJourney,
                    schedule.slug,
                    startMoment
                )
            } else {
                clinicalMilestone.isTask = false
                clinicalMilestone.isNeedingReview = false
                clinicalMilestone.isClickable = false
            }
        }
    }

    _onClickPatientClinicalMilestoneOfType_rtmReminder(clinicalMilestone, patient) {
        store.commit('popup/setConfig', {
            patient: patient,
            milestone: clinicalMilestone.milestone,
            clinicalMilestone: clinicalMilestone
        })
        store.commit('popup/setClass', 'PopupRtmScheduledReminder')

        Analytics.sendEvent('rtmScheduledReminderNeedsReview', {
            patientId: patient.personaId
        })
    }

    _resolvePatientClinicalMilestoneOfType_rtmUnregisteredReview(
        inMilestone,
        patient,
        patientJourney,
        clinicalMilestones
    ) {
        const journey = store.state.resources.journeys[patientJourney.journeySlug]

        if (!journey.hasRtm) {
            return
        }

        if (!inMilestone.reviewSchedules || inMilestone.reviewSchedules.length == 0) {
            return
        }

        const registrationMilestone = patient.getMilestoneOfSlug('registration')
        const rtmDischargeMilestone = patientJourney.getMilestoneOfSlug('rtm-discharge')

        for (const reviewSchedule of inMilestone.reviewSchedules) {
            const schedule = Schedule.get(reviewSchedule)

            const [startMoment] = PatientService.getPatientScheduleStartEndMoments(
                patient,
                schedule.slug,
                patientJourney
            )

            if (
                !startMoment ||
                startMoment.isAfter(moment()) ||
                (inMilestone.startDate && startMoment.isBefore(moment(inMilestone.startDate)))
            ) {
                continue
            }

            const reviewMilestone = PatientService.getUnregisteredPatientRtmScheduledReviewMilestone(
                patientJourney,
                schedule.slug
            )

            if (!reviewMilestone && (registrationMilestone || rtmDischargeMilestone)) {
                continue
            }

            const clinicalMilestone = _.clone(inMilestone)
            clinicalMilestones.push(clinicalMilestone)

            clinicalMilestone.moment = startMoment
            clinicalMilestone.leftIconFilename = ClinicalMilestone.Icon.reminder

            clinicalMilestone.leftText = Locale.getLanguageItem('clinicalMilestonePatientHasNotRegistered')

            clinicalMilestone.milestone = reviewMilestone
            clinicalMilestone.scheduleSlug = schedule.slug

            clinicalMilestone.rightIconFilename = ClinicalMilestone.Icon.pending
            clinicalMilestone.isNeedingReview = !reviewMilestone
            clinicalMilestone.isClickable = true
            clinicalMilestone.isTask = TaskService.isRtmUnregisteredPatientTask(
                patientJourney,
                schedule.slug,
                startMoment
            )

            if (reviewMilestone && reviewMilestone.reviewerId) {
                const reviewedBy = this.getMilestoneReviewerName(reviewMilestone)

                clinicalMilestone.rightText = Locale.getLanguageItem('clinicalMilestoneReviewed', [
                    reviewedBy,
                    moment(reviewMilestone.date).format(Utils.readableDateFormat)
                ])

                clinicalMilestone.rightIconFilename = ClinicalMilestone.Icon.complete
            }
        }
    }

    _onClickPatientClinicalMilestoneOfType_rtmUnregisteredReview(clinicalMilestone, patient) {
        store.commit('popup/setConfig', {
            patient: patient,
            milestone: clinicalMilestone.milestone,
            clinicalMilestone: clinicalMilestone
        })
        store.commit('popup/setClass', 'PopupRtmUnregisteredPatientReview')

        Analytics.sendEvent('rtmUnregisteredPatientReview', {
            patientId: patient.personaId,
            scheduleSlug: clinicalMilestone.scheduleSlug
        })
    }

    _resolvePatientClinicalMilestoneOfType_rtmStepsReview(inMilestone, patient, patientJourney, clinicalMilestones) {
        const journey = store.state.resources.journeys[patientJourney.journeySlug]

        if (!journey.hasRtm) {
            return
        }

        if (!inMilestone.reviewSchedules || inMilestone.reviewSchedules.length == 0) {
            return
        }

        const registrationMilestone = patient.getMilestoneOfSlug('registration')

        if (!registrationMilestone) {
            return
        }

        for (const reviewSchedule of inMilestone.reviewSchedules) {
            const schedule = Schedule.get(reviewSchedule)

            const [startMoment] = PatientService.getPatientScheduleStartEndMoments(
                patient,
                schedule.slug,
                patientJourney
            )

            if (
                !startMoment ||
                startMoment.isAfter(moment()) ||
                (inMilestone.startDate && startMoment.isBefore(moment(inMilestone.startDate)))
            ) {
                continue
            }

            const reviewMilestone = PatientService.getPatientScheduledReviewMilestone(
                patientJourney,
                'rtm-steps-review',
                schedule.slug
            )

            if (!reviewMilestone && patient.hasSteps) {
                return
            }

            const clinicalMilestone = _.clone(inMilestone)
            clinicalMilestones.push(clinicalMilestone)

            clinicalMilestone.moment = startMoment
            clinicalMilestone.leftIconFilename = ClinicalMilestone.Icon.reminder

            clinicalMilestone.leftText = Locale.getLanguageItem('clinicalMilestonePatientHasNotSharedSteps')

            clinicalMilestone.milestone = reviewMilestone
            clinicalMilestone.scheduleSlug = schedule.slug

            clinicalMilestone.rightIconFilename = ClinicalMilestone.Icon.pending
            clinicalMilestone.isNeedingReview = !reviewMilestone
            clinicalMilestone.isClickable = true
            clinicalMilestone.isTask = TaskService.isRtmUnsharedStepsTask(patientJourney, schedule.slug, startMoment)

            if (reviewMilestone && reviewMilestone.reviewerId) {
                const reviewedBy = store.state.user.users[reviewMilestone.reviewerId].titledFullName

                clinicalMilestone.rightText = Locale.getLanguageItem('clinicalMilestoneReviewed', [
                    reviewedBy,
                    moment(reviewMilestone.date).format(Utils.readableDateFormat)
                ])

                clinicalMilestone.rightIconFilename = ClinicalMilestone.Icon.complete
            }
        }
    }

    _onClickPatientClinicalMilestoneOfType_rtmStepsReview(clinicalMilestone, patient) {
        store.commit('popup/setConfig', {
            patient: patient,
            milestone: clinicalMilestone.milestone,
            clinicalMilestone: clinicalMilestone
        })
        store.commit('popup/setClass', 'PopupRtmUnsharedStepsReview')

        Analytics.sendEvent('rtmUnsharedStepsPatientReview', {
            patientId: patient.personaId,
            scheduleSlug: clinicalMilestone.scheduleSlug
        })
    }

    _resolvePatientClinicalMilestoneOfType_rtmInsufficientStepsReview(
        inMilestone,
        patient,
        patientJourney,
        clinicalMilestones
    ) {
        const journey = store.state.resources.journeys[patientJourney.journeySlug]

        if (!journey.hasRtm) {
            return
        }

        if (!inMilestone.reviewSchedules || inMilestone.reviewSchedules.length == 0) {
            return
        }

        const registrationMilestone = patient.getMilestoneOfSlug('registration')

        if (!registrationMilestone || !patient.hasSteps) {
            return
        }

        for (const reviewSchedule of inMilestone.reviewSchedules) {
            const schedule = Schedule.get(reviewSchedule)

            const [startMoment] = PatientService.getPatientScheduleStartEndMoments(
                patient,
                schedule.slug,
                patientJourney
            )

            if (
                !startMoment ||
                startMoment.isAfter(moment()) ||
                (inMilestone.startDate && startMoment.isBefore(moment(inMilestone.startDate)))
            ) {
                continue
            }

            // With feature flag RTM_PERIOD_REFACTOR, this applies to RTM review milestones with RTM code 98977
            const { FeatureFlag, getFeatureFlag } = useFeatureFlags()
            const rtm98977MilestoneCode = getFeatureFlag(FeatureFlag.rtmPeriodRefactor)
                ? RtmPeriodReviewMilestone.Code.rtm_98977
                : RtmPeriodReviewMilestone.Code.rtm_98980

            const rtmPeriodReviewMilestones = PatientService.getValidPatientRtmPeriodReviewMilestonesByCode(
                patient,
                rtm98977MilestoneCode
            )

            const reviewMilestone = PatientService.getPatientScheduledReviewMilestone(
                patientJourney,
                'rtm-insufficient-steps-review',
                schedule.slug
            )

            if (!reviewMilestone && rtmPeriodReviewMilestones.length > 0) {
                return
            }

            const clinicalMilestone = _.clone(inMilestone)
            clinicalMilestones.push(clinicalMilestone)

            clinicalMilestone.moment = startMoment
            clinicalMilestone.leftIconFilename = ClinicalMilestone.Icon.reminder

            clinicalMilestone.leftText = Locale.getLanguageItem('clinicalMilestonePatientInsufficientSteps')

            clinicalMilestone.milestone = reviewMilestone
            clinicalMilestone.scheduleSlug = schedule.slug

            clinicalMilestone.rightIconFilename = ClinicalMilestone.Icon.pending
            clinicalMilestone.isNeedingReview = !reviewMilestone
            clinicalMilestone.isClickable = true
            clinicalMilestone.isTask = TaskService.isRtmInsufficientStepsTask(
                patientJourney,
                schedule.slug,
                startMoment
            )

            if (reviewMilestone && reviewMilestone.reviewerId) {
                const reviewedBy = store.state.user.users[reviewMilestone.reviewerId].titledFullName

                clinicalMilestone.rightText = Locale.getLanguageItem('clinicalMilestoneReviewed', [
                    reviewedBy,
                    moment(reviewMilestone.date).format(Utils.readableDateFormat)
                ])

                clinicalMilestone.rightIconFilename = ClinicalMilestone.Icon.complete
            }
        }
    }

    _onClickPatientClinicalMilestoneOfType_rtmInsufficientStepsReview(clinicalMilestone, patient) {
        store.commit('popup/setConfig', {
            patient: patient,
            milestone: clinicalMilestone.milestone,
            clinicalMilestone: clinicalMilestone
        })
        store.commit('popup/setClass', 'PopupRtmInsufficientStepsReview')

        Analytics.sendEvent('rtmPatientInsufficientStepsReview', {
            patientId: patient.personaId,
            scheduleSlug: clinicalMilestone.scheduleSlug
        })
    }

    _resolvePatientClinicalMilestoneOfType_taskReview(inMilestone, patient, patientJourney, clinicalMilestones) {
        const customTasks = patientJourney.getMilestonesOfSlug('custom-task')

        customTasks.forEach(milestone => {
            const clinicalMilestone = _.clone(inMilestone)
            clinicalMilestones.push(clinicalMilestone)

            clinicalMilestone.moment = moment(milestone.moment)
            clinicalMilestone.milestone = milestone
            clinicalMilestone.leftIconFilename = ClinicalMilestone.Icon.survey
            clinicalMilestone.leftText = milestone.title
            clinicalMilestone.rightIconFilename = ClinicalMilestone.Icon.pending
            clinicalMilestone.isClickable = false
            clinicalMilestone.isTask = TaskService.isCustomTaskReviewTask(patientJourney, milestone)
            clinicalMilestone.isNeedingReview = clinicalMilestone.isTask

            if (milestone && milestone.reviewerId) {
                const completedBy = store.state.user.users[milestone.reviewerId].titledFullName
                const completedDate = milestone.reviewedDate
                    ? moment(milestone.reviewedDate).format(Utils.readableDateFormat)
                    : ''

                clinicalMilestone.rightText = Locale.getLanguageItem('clinicalMilestoneCompleted', [
                    completedBy,
                    completedDate
                ])

                clinicalMilestone.rightIconFilename = ClinicalMilestone.Icon.complete
            }

            if (milestone.date <= moment().format(Utils.serialisedDateFormat)) {
                clinicalMilestone.isClickable = true
            }
        })
    }

    _onClickPatientClinicalMilestoneOfType_taskReview(clinicalMilestone, patient) {
        store.commit('popup/setConfig', {
            patient: patient,
            milestone: clinicalMilestone.milestone,
            clinicalMilestone: clinicalMilestone
        })
        store.commit('popup/setClass', 'PopupCustomTaskReview')

        Analytics.sendEvent('customTaskReview', {
            patientId: patient.personaId,
            patientJourneyMilestoneId: clinicalMilestone.milestone.id
        })
    }

    _resolvePatientClinicalMilestoneOfType_bundleEndDate(inMilestone, patient, patientJourney, clinicalMilestones) {
        const dischargeMilestone = patientJourney.getMilestoneOfSlug('discharge')
        if (!dischargeMilestone) {
            return
        }

        const scheduleMoments = PatientService.getPatientScheduleStartEndMoments(patient, '90d-post-dis')
        const endMoment = scheduleMoments[1]

        if (endMoment) {
            const clinicalMilestone = _.clone(inMilestone)
            clinicalMilestones.push(clinicalMilestone)
            clinicalMilestone.moment = endMoment
            clinicalMilestone.leftIconFilename = ClinicalMilestone.Icon.survey
            clinicalMilestone.leftText = Locale.getLanguageItem('clinicalMilestoneBundleEndDate', [
                endMoment.format(Utils.readableDateFormat)
            ])
        }
    }

    /**
     * Function can be passed either:
     * - A SurveyResult, where the row represents a concrete result
     * - A ScheduleEvent, where the row represents an expected result from this period
     */
    _resolvePatientJourneyObjectOfType_survey(object, patient, patientJourney, clinicalMilestones, spec) {
        const nowMoment = moment()
        const nowDate = nowMoment.format(Utils.serialisedDateFormat)
        const journey = store.state.resources.journeys[patientJourney.journeySlug]

        if (object instanceof SurveyResult) {
            const canBeCompletedByClinician = this.canClinicianCompleteSurvey({
                config: this.getClinicianCompletableSurveysConfig({
                    user: store.getters.user,
                    owner: store.getters.owner
                }),
                contentSlug: object.surveySlug
            })

            const clinicalMilestone = new ClinicalMilestone({
                type: ClinicalMilestone.Type.survey,
                spec: undefined,
                scheduleSlug: object.scheduleSlug,
                contentSlug: object.surveySlug
            })
            const survey = store.state.content.content[object.surveySlug]
            const schedule = Schedule.get(object.scheduleSlug)
            clinicalMilestones.push(clinicalMilestone)
            clinicalMilestone.leftText = this.getRowTitleForSurveySchedule(survey, schedule)
            clinicalMilestone.moment = object.moment
            // Required only for old-style ListColumn.Type.clinicalMilestone
            clinicalMilestone.scheduleSlug = object.scheduleSlug

            clinicalMilestone.surveyResult = object // latest result
            clinicalMilestone.activitySlug = object.activitySlug
            clinicalMilestone.rightText = SurveyResultsService.getPatientSurveyResultReviewedText(patient, object)
            clinicalMilestone.isCancellable = spec.isCancellable
            clinicalMilestone.isNeedingReview = SurveyResultsService.isPatientSurveyResultNeedingReview(patient, object)
            clinicalMilestone.isTask = TaskService.isPatientSurveyResultReviewTask(patient, object)
            clinicalMilestone.isDownloadPdfTask = TaskService.isSurveyDownloadPdfTask(patient, object)
            clinicalMilestone.isHidden = this.isClinicalMilestoneHidden({
                patientJourney: patientJourney,
                surveyResult: object,
                config: spec,
                scheduleSlug: object.scheduleSlug
            })
            clinicalMilestone.patientJourneyId = patientJourney.patientJourneyId

            const user = store.state.user.user
            const clinicalMilestoneStatus = SurveyResultsService.getSurveyResultMilestoneStatusObject(
                user,
                patient,
                object,
                canBeCompletedByClinician
            )

            clinicalMilestone.status = clinicalMilestoneStatus.status
            clinicalMilestone.leftIconFilename = clinicalMilestoneStatus.leftIcon
            clinicalMilestone.rightIconFilename = clinicalMilestoneStatus.rightIcon
            clinicalMilestone.rightText = clinicalMilestoneStatus.rightText || clinicalMilestone.rightText
            clinicalMilestone.isClickable = true

            if (this.isLoggingVerbose) {
                Logging.log(`Adding row: ${object.status} SurveyResult with resultPrmId ${
                    object.resultPrmId
                }, slug ${survey.slug},
                schedule ${schedule.slug}, datetime ${clinicalMilestone.moment.format(Utils.serialisedDateTimeFormat)},
                isNeedingReview ${clinicalMilestone.isNeedingReview}, isTask ${
                    clinicalMilestone.isTask
                }, isDownloadPdfTask ${clinicalMilestone.isDownloadPdfTask}`)
            }
        } else if (object instanceof ScheduleEvent) {
            // Display single row, for no results
            let activity =
                journey.activitiesMap[object.activitySlug] || ActivityListService.getActivityBySlug(object.activitySlug)

            if (!activity) {
                Logging.warn(`Activity with slug ${object.activitySlug} not found`)

                return
            }

            const survey = store.state.content.content[activity.contentSlug]
            const schedule = Schedule.get(activity.scheduleSlug)
            const canBeCompletedByClinician = this.canClinicianCompleteSurvey({
                config: this.getClinicianCompletableSurveysConfig({
                    user: store.getters.user,
                    owner: store.getters.owner
                }),
                contentSlug: activity.contentSlug
            })

            // If schedule is non-repeating and "pre", use the endDate
            let rowMoment
            if (!schedule.isRepeating && schedule.startOffset < 0) {
                rowMoment = moment(object.endDate).subtract(4, 'hours')
            } else {
                rowMoment = moment(object.startDate)
            }
            // Ignore rows that would have date prior to reg date
            const regDate = patient.getMilestoneOfSlugDate('registration')
            if (regDate && rowMoment.format(Utils.serialisedDateFormat) < regDate) {
                return
            }
            const clinicalMilestone = new ClinicalMilestone({
                type: ClinicalMilestone.Type.survey,
                spec: undefined,
                scheduleSlug: undefined,
                contentSlug: activity.contentSlug
            })
            clinicalMilestone.activitySlug = activity.slug
            clinicalMilestones.push(clinicalMilestone)
            clinicalMilestone.moment = rowMoment
            clinicalMilestone.leftIconFilename = ClinicalMilestone.Icon.survey
            clinicalMilestone.leftText = this.getRowTitleForSurveySchedule(survey, schedule)
            // Required only for old-style ListColumn.Type.clinicalMilestone
            clinicalMilestone.scheduleSlug = activity.scheduleSlug
            clinicalMilestone.isCancellable = spec.isCancellable
            clinicalMilestone.patientJourneyId = patientJourney.patientJourneyId
            if (this.isLoggingVerbose) {
                Logging.log(`Adding row: Pending SurveyResult with slug ${survey.slug},
                schedule ${schedule.slug}, datetime ${clinicalMilestone.moment.format(Utils.serialisedDateTimeFormat)}`)
            }

            if (nowDate < object.startDate) {
                clinicalMilestone.status = ClinicalMilestone.Status.future
                clinicalMilestone.rightIconFilename = ClinicalMilestone.Icon.future
            } else if (nowDate > object.endDate) {
                clinicalMilestone.status = ClinicalMilestone.Status.past
                clinicalMilestone.rightIconFilename = ClinicalMilestone.Icon.past
            } else {
                clinicalMilestone.status = ClinicalMilestone.Status.pending
                clinicalMilestone.rightIconFilename = ClinicalMilestone.Icon.pending
            }

            // If the schedule event has started or has elapsed and config allows for clinician completable surveys
            if (canBeCompletedByClinician && object.startDate <= nowDate) {
                clinicalMilestone.status = ClinicalMilestone.Status.pendingClinicianCompletable
                clinicalMilestone.scheduleSlug = activity.scheduleSlug
                clinicalMilestone.isClickable = true
                clinicalMilestone.rightText = Locale.getLanguageItem('clinicalMilestoneCompleteForPatient')

                return
            }

            clinicalMilestone.isHidden = this.isClinicalMilestoneHidden({
                patientJourney: patientJourney,
                config: spec,
                scheduleSlug: activity.scheduleSlug
            })
        }
    }

    /**
     * Create ClinicalMilestones for remaining survey results matching the spec and not already covered by other
     * ClinicalMilestone rows.
     */
    _resolvePatientClinicalMilestoneOfType_surveyCatchAll(
        inMilestone,
        patient,
        patientJourney,
        clinicalMilestones,
        spec
    ) {
        // Find all SurveyResults matching the catch-all spec, that are not already referenced by existing
        // milestones
        const existingMilestoneResults = clinicalMilestones.reduce((total, cm) => {
            if (cm.surveyResult) {
                total.push(cm.surveyResult)
            }

            return total
        }, [])
        const contentSlugs = this.getContentSlugsFromSpec(inMilestone.spec)
        const surveyResults = (patient.surveyResults || []).filter(surveyResult => {
            const survey = store.state.content.surveys[surveyResult.surveySlug]

            return (
                survey &&
                contentSlugs.includes(surveyResult.surveySlug) &&
                !existingMilestoneResults.includes(surveyResult)
            )
        })
        surveyResults.forEach(result => {
            const clinicalMilestone = _.clone(inMilestone)
            const survey = store.state.content.surveys[result.surveySlug]
            const schedule = Schedule.get(result.scheduleSlug)

            clinicalMilestones.push(clinicalMilestone)
            clinicalMilestone.type = ClinicalMilestone.Type.survey
            clinicalMilestone.contentSlug = result.surveySlug
            clinicalMilestone.scheduleSlug = result.scheduleSlug
            clinicalMilestone.leftIconFilename = ClinicalMilestone.Icon.survey
            clinicalMilestone.moment = result.moment
            clinicalMilestone.status = ClinicalMilestone.Status.complete
            clinicalMilestone.rightIconFilename = ClinicalMilestone.Icon.complete

            clinicalMilestone.isHidden = this.isClinicalMilestoneHidden({
                patientJourney: patientJourney,
                surveyResult: result,
                config: spec,
                scheduleSlug: result.scheduleSlug
            })

            clinicalMilestone.surveyResult = result
            clinicalMilestone.leftText = this.getRowTitleForSurveySchedule(
                survey,
                schedule,
                clinicalMilestone.surveyResult
            )
            clinicalMilestone.rightText = SurveyResultsService.getPatientSurveyResultReviewedText(
                patient,
                clinicalMilestone.surveyResult
            )
            clinicalMilestone.isClickable = true
            clinicalMilestone.isNeedingReview = SurveyResultsService.isPatientSurveyResultNeedingReview(
                patient,
                clinicalMilestone.surveyResult
            )
            clinicalMilestone.isTask = TaskService.isPatientSurveyResultReviewTask(
                patient,
                clinicalMilestone.surveyResult
            )
            clinicalMilestone.isDownloadPdfTask = TaskService.isSurveyDownloadPdfTask(
                patient,
                clinicalMilestone.surveyResult
            )
        })
    }

    _resolvePatientClinicalMilestoneOfType_carePeriod(inMilestone, patient, patientJourney, clinicalMilestones) {
        const milestones = patient.getMilestonesOfSlug('care-period')
        const providers = store.state.resources.providers

        milestones.forEach(milestone => {
            const clinicalMilestone = _.clone(inMilestone)
            clinicalMilestones.push(clinicalMilestone)
            clinicalMilestone.leftIconFilename = ClinicalMilestone.Icon.survey

            const periodType = Locale.getLanguageItemForModelEnum('periodType', milestone.periodType)

            if (!providers[milestone.providerSlug]) {
                Logging.error(`No provider "${milestone.providerSlug}" in providers list`)
            }

            clinicalMilestone.leftText = Locale.getLanguageItem('clinicalMilestoneCareNavigation', [
                periodType,
                providers[milestone.providerSlug]?.title,
                moment(milestone.date).format(Utils.readableDateFormat)
            ])
            clinicalMilestone.moment = milestone.moment
        })
    }

    _onClickPatientClinicalMilestoneOfType_survey(clinicalMilestone, patient) {
        if (clinicalMilestone.surveyResult?.status == SurveyResult.Status.complete) {
            const survey = store.state.content.surveys[clinicalMilestone.contentSlug]
            const surveyResultVersion = clinicalMilestone.surveyResult.version

            if (
                !surveyResultVersion ||
                survey.version == surveyResultVersion ||
                survey.versions.find(v => v.version == surveyResultVersion)
            ) {
                SurveyResultsService.requestAndDisplayPatientSurveyResult(clinicalMilestone.surveyResult)

                return
            }

            ResourceService.requestContentBySlugVersion(
                [clinicalMilestone.contentSlug],
                surveyResultVersion,
                survey.locale
            ).then(contentBySlugVersion => {
                store.commit('setContent', contentBySlugVersion.data)
                SurveyResultsService.requestAndDisplayPatientSurveyResult(clinicalMilestone.surveyResult)
            })

            return
        }

        if (clinicalMilestone.surveyResult?.status == SurveyResult.Status.partial) {
            this.onPartialSurveyResultClick(clinicalMilestone, patient)

            return
        }

        const canBeCompletedByClinician = this.canClinicianCompleteSurvey({
            config: this.getClinicianCompletableSurveysConfig({
                user: store.getters.user,
                owner: store.getters.owner
            }),
            contentSlug: clinicalMilestone.contentSlug
        })

        if (canBeCompletedByClinician) {
            store.commit('popup/setConfig', {
                title: Locale.getLanguageItem('clinicalMilestonePopupCompleteForPatientTitle'),
                body: Locale.getLanguageItem('clinicalMilestonePopupCompleteForPatientText'),
                rightButtonText: Locale.getLanguageItem('clinicalMilestonePopupCompleteForPatientConfirm'),
                leftButtonText: Locale.getLanguageItem('clinicalMilestonePopupCompleteForPatientCancel'),
                onRightButton: () =>
                    SurveyResultsService.displayPatientSurveyForClinicianCompletion({
                        clinicalMilestone,
                        patient
                    }),
                isLeftButtonSecondary: true
            })
            store.commit('popup/setClass', 'PopupTwoButtons')
        }
    }

    _onClickRightSidePatientClinicalMilestoneOfType_survey(clinicalMilestone, patient) {
        if (!clinicalMilestone.surveyResult) {
            this._onClickPatientClinicalMilestoneOfType_survey(clinicalMilestone, patient)

            return
        }

        const journey = store.state.resources.journeys[clinicalMilestone.surveyResult.journeySlug]
        const content = journey.activitiesMap[clinicalMilestone.surveyResult.activitySlug]

        if (!content) {
            const user = store.state.user.user
            const enteredBy = SurveyResultsService.getCreatedByUserObject(clinicalMilestone.surveyResult, user, patient)

            store.commit('popup/setConfig', {
                title: Locale.getLanguageItem('clinicalMilestonePopupNoContentTitle'),
                text: Locale.getLanguageItem('clinicalMilestonePopupNoContentBody', [enteredBy.fullName]),
                buttonText: Locale.getLanguageItem('cancel')
            })
            store.commit('popup/setClass', 'PopupOneButton')

            return
        }

        if (clinicalMilestone.surveyResult?.status == SurveyResult.Status.partial) {
            if (
                !clinicalMilestone.surveyResult.enteredByClinician &&
                store.state.user.owner.hasFeatureFlag('canEditPatientSurveyResults')
            ) {
                SurveyResultsService.displayConfirmDiscardPatientSurveyResult(clinicalMilestone, patient)

                return
            }

            this.onPartialSurveyResultClick(clinicalMilestone, patient)

            return
        }

        // Clinician clicking on patient's results
        if (
            store.state.user.owner.hasFeatureFlag('canEditPatientSurveyResults') &&
            !clinicalMilestone.surveyResult?.enteredByClinician
        ) {
            store.commit('popup/setConfig', {
                title: Locale.getLanguageItem('clinicalMilestonePopupAmendOrAppendPatientResultTitle'),
                body: Locale.getLanguageItem('clinicalMilestonePopupAmendOrAppendPatientResultBody'),
                leftButtonText: Locale.getLanguageItem('clinicalMilestonePopupAmendResult'),
                rightButtonText: Locale.getLanguageItem('clinicalMilestonePopupAppendResult'),
                onRightButton: () =>
                    SurveyResultsService.displayPatientSurveyForClinicianCompletion({
                        clinicalMilestone,
                        patient,
                        amendResult: false,
                        resetSurveyResult: true
                    }),
                onLeftButton: () =>
                    SurveyResultsService.displayConfirmAmendPatientSurveyResult(clinicalMilestone, patient),
                isLeftButtonSecondary: true
            })
            store.commit('popup/setClass', 'PopupTwoButtons')

            return
        }

        // Clinician clicking on clinician's results (theirs or other clinician's)
        if (clinicalMilestone.surveyResult?.enteredByClinician) {
            store.commit('popup/setConfig', {
                title: Locale.getLanguageItem('clinicalMilestonePopupAmendClinicianResultTitle'),
                body: Locale.getLanguageItem('clinicalMilestonePopupAmendClinicianResultBody'),
                leftButtonText: Locale.getLanguageItem('cancel'),
                rightButtonText: Locale.getLanguageItem('confirm'),
                onRightButton: () =>
                    SurveyResultsService.displayPatientSurveyForClinicianCompletion({
                        clinicalMilestone,
                        patient,
                        amendResult: true
                    }),
                onLeftButton: () => store.commit('popup/dismiss'),
                isLeftButtonSecondary: true
            })
            store.commit('popup/setClass', 'PopupTwoButtons')
        }
    }

    onPartialSurveyResultClick(clinicalMilestone, patient) {
        const user = store.state.user.user
        const enteredBy = SurveyResultsService.getCreatedByUserObject(clinicalMilestone.surveyResult, user, patient)
        const isEnteredByUser = enteredBy?.personaId == user.personaId

        if (!clinicalMilestone.surveyResult.enteredByClinician || !isEnteredByUser) {
            SurveyResultsService.requestAndDisplayPatientSurveyResult(clinicalMilestone.surveyResult)

            return
        }

        SurveyResultsService.displayPatientSurveyForClinicianCompletion({ clinicalMilestone, patient })
    }

    /**
     * From an array of ClinicalMilestones, and an existing array to remove, add to the remove array any
     * post-reg ClinicalMilestones where there is a matching pre-op one.
     * There should only be a pre-op object in the array if the patient has an operation date.
     */
    _removePostRegIfMatchingPreOp(clinicalMilestones) {
        const postRegSurveyMilestones = clinicalMilestones.filter(
            cm => cm.type == ClinicalMilestone.Type.survey && cm.scheduleSlug == 'post-reg'
        )
        const removeMilestones = []
        postRegSurveyMilestones.forEach(postRegMilestone => {
            const matchingPreOpMilestone = clinicalMilestones.find(
                cm => cm.contentSlug == postRegMilestone.contentSlug && cm.scheduleSlug.includes('pre-op')
            )
            if (matchingPreOpMilestone) {
                removeMilestones.push(postRegMilestone)
            }
        })

        return clinicalMilestones.filter(cm => !removeMilestones.includes(cm))
    }

    /**
     * From a list of ClinicalMilestones, find all matching the provided specification. The specification
     * is provided by way of an object, assumed to be a ListColumn, but could be any object with the same
     * key attributes.
     */
    getClinicalMilestonesMatching(clinicalMilestones, object) {
        const matchingMilestones = []
        for (const clinicalMilestone of clinicalMilestones) {
            if (clinicalMilestone.type == object.clinicalMilestoneType) {
                let clinicalMilestoneSchedule
                let objectSchedule
                // Get schedules
                if (clinicalMilestone.scheduleSlug) {
                    clinicalMilestoneSchedule = Schedule.get(clinicalMilestone.scheduleSlug)
                }
                if (object.scheduleSlug) {
                    objectSchedule = Schedule.get(object.scheduleSlug)
                }
                if (clinicalMilestoneSchedule == undefined || objectSchedule == undefined) {
                    continue
                }
                switch (clinicalMilestone.type) {
                    case ClinicalMilestone.Type.date:
                        if (clinicalMilestones.milestone == object.dateMilestone) {
                            matchingMilestones.push(clinicalMilestone)
                        }
                        break

                    case ClinicalMilestone.Type.survey:
                        if (objectSchedule.overlaps(clinicalMilestoneSchedule)) {
                            var survey = store.state.content.surveys[clinicalMilestone.contentSlug]
                            if (
                                (!!object.contentSlug && survey.slug == object.contentSlug) ||
                                (!!object.contentSlugs && object.contentSlugs.includes(survey.slug)) ||
                                (!!object.contentTag && survey.containsTag(object.contentTag))
                            ) {
                                matchingMilestones.push(clinicalMilestone)
                            }
                        }
                        break
                }
            }
        }

        return matchingMilestones
    }

    /**
     * From an array of ClinicalMilestones, each with their own status, determine the overall status of the cell.
     * If requireAllComplete == true, all must be complete to show the complete icon.
     */
    getStatusFromClinicalMilestones(clinicalMilestones, requireAllComplete = false) {
        // Get Set of statuses
        const statusSet = new Set([...clinicalMilestones.map(cm => cm.status)])
        if (statusSet.has(ClinicalMilestone.Status.complete)) {
            if (!requireAllComplete || statusSet.size == 1) {
                return ClinicalMilestone.Status.complete
            }
        }
        if (statusSet.has(ClinicalMilestone.Status.pending)) {
            return ClinicalMilestone.Status.pending
        }
        if (statusSet.has(ClinicalMilestone.Status.future) && statusSet.size == 1) {
            return ClinicalMilestone.Status.future
        }
        if (statusSet.has(ClinicalMilestone.Status.past) && statusSet.size == 1) {
            return ClinicalMilestone.Status.past
        }

        // Mixture of future and past - we don't expect this to happen, for debugging purposes
        // make it clear
        return ClinicalMilestone.Status.unknown
    }

    /**
     * Filter out any patient clinical milestones where the milestone schedule overlaps
     * the specified schedule, but the milestone SurveyResult is actually outside the specified schedule.
     */
    filterPatientClinicalMilestonesOutsideScheduleWindow(patient, clinicalMilestones, scheduleSlug) {
        const filteredMilestones = clinicalMilestones.filter(clinicalMilestone => {
            if (scheduleSlug.includes('always') || clinicalMilestone.scheduleSlug.includes('always')) {
                // Don't remove anything if either schedule is 'always'
                return true
            }
            const specifiedSchedule = Schedule.get(scheduleSlug)
            const milestoneSchedule = Schedule.get(clinicalMilestone.scheduleSlug)
            if (clinicalMilestone.surveyResult) {
                if (!specifiedSchedule.overlaps(milestoneSchedule)) {
                    // Don't remove anything if the schedules don't actually overlap
                    return true
                }
                const result = PatientService.isPatientScheduleActiveAtMoment(
                    patient,
                    scheduleSlug,
                    clinicalMilestone.surveyResult.moment
                )
                if (!result) {
                    // Milestone has a survey result which is OUTSIDE the specified schedule, even though the milestone schedule
                    // overlaps the specified schedule
                    Logging.warn(
                        `Removed ClinicalMilestone (patient: ${patient.personaId}, contentSlug: ${
                            clinicalMilestone.contentSlug
                        }, scheduleSlug: ${
                            clinicalMilestone.scheduleSlug
                        }) because SurveyResult moment: ${clinicalMilestone.surveyResult.moment.format()} was outside specified scheduleSlug: ${scheduleSlug}`
                    )
                }

                return result
            }
            // Milestone has no SurveyResult - remove if milestone scheduleSlug not contained within window scheduleSlug
            const milestoneScheduleWithinSchedule = specifiedSchedule.contains(milestoneSchedule)

            return milestoneScheduleWithinSchedule
        })

        return filteredMilestones
    }

    /**
     * For a survey row in the clinical milestones chart, determine the title, as follows:
     * IF a PROM survey, we write "PROM" ELSE we write "Survey"
     * IF a repeating schedule or small schedule window: format "6-month PROM: Oxford Hip" if at a schedule interval in our local list, else "PROM: Oxford Hip"
     * Examples of schedule summaries:
     * --PRE--
     * pre-op -> Operation (really we expect to stringmap the schedule slug)
     * --POST--
     * 0y-1y-post-reg -> Registration
     * 3m-6m-post-op => 3m
     * 3m-post-dis => 3m Discharge
     * --SPAN--
     * 6w-6w-span-op -> Operation (really we expect to stringmap the schedule slug)
     */
    getRowTitleForSurveySchedule(survey, schedule, surveyResult) {
        // Read TabCptResultsClinicalMilestones config directly to see if we should display survey name only
        const owner = store.state.user.owner
        let surveyName = ContentService.sanitiseText(survey.name || '')

        if (surveyResult && TaskService.displaySurveyResultScoreForTask(surveyResult) && surveyResult.score) {
            surveyName = `${surveyName} (${surveyResult.score} / ${survey.maxScore})`
        }

        if (owner) {
            const config = owner.getPatientPageTabCptConfig('TabCptResultsClinicalMilestones')
            if (config && config.surveyRowLabel == 'nameOnly') {
                return surveyName //+ ` ${schedule.slug}` // add for debugging
            }
        }
        // Calculate compound name
        const scheduleSummary = schedule.summary

        const key = survey.isPromSurvey ? 'clinicalMilestoneRowProm' : 'clinicalMilestoneRowSurvey'
        const title = Locale.getLanguageItem(key, [scheduleSummary || '', surveyName]) || surveyName

        return StringHelper.capitalize(title.trim())
    }

    /**
     * For a clinical milestone status, get the text to display when hovering an icon.
     * isCurrent=true if the column is showing the patient current PROM window, rather than a fixed window.
     */
    getStatusHoverText(status, numComplete, numAvailable, isCurrent) {
        if (isCurrent && numAvailable == 0) {
            return Locale.getLanguageItem('patientListSurveysNothingDue')
        }
        let stringKey
        if (status == ClinicalMilestone.Status.complete) {
            stringKey = 'clinicalMilestoneStatusSummaryComplete'
        } else if (
            status == ClinicalMilestone.Status.pending ||
            status == ClinicalMilestone.Status.pendingCompletable
        ) {
            if (numComplete && numAvailable) {
                return Locale.getLanguageItem('patientListSurveysNumCompleted', [numComplete, numAvailable])
            }
            stringKey = 'clinicalMilestoneStatusSummaryPending'
        } else if (status == ClinicalMilestone.Status.past) {
            stringKey = 'clinicalMilestoneStatusSummaryExpired'
        }

        return stringKey ? Locale.getLanguageItemOrUndefined(stringKey) : undefined
    }

    getMilestoneMoment(clinicalMilestone, milestone) {
        const nowDate = new moment().format(Utils.serialisedDateFormat)
        if (nowDate >= milestone.date && nowDate <= milestone.endDate) {
            clinicalMilestone.moment = moment()
        } else {
            clinicalMilestone.moment = moment(milestone.endDate)
        }
    }

    isClinicalMilestoneHidden({ patientJourney, surveyResult, config, scheduleSlug }) {
        if (scheduleSlug != 'always-rep') {
            return false
        }

        if (!surveyResult) {
            return true
        }

        if (config.rtmOnly) {
            const journey = store.state.resources.journeys[patientJourney.journeySlug]

            if (!journey.hasRtm) {
                return true
            }
        }

        const hasSurveyResultReviewed = SurveyResultsService.isPatientSurveyResultReviewed(surveyResult)

        // Ignore startDate and minScore config for already reviewed surveys
        if (hasSurveyResultReviewed) {
            return false
        }

        if (!config.startDate && !config.minScore) {
            return true
        }

        if (config.startDate) {
            const surveyEndDate = moment(surveyResult.endTime).format(Utils.serialisedDateFormat)

            if (surveyEndDate < config.startDate) {
                return true
            }
        }

        return config.minScore && surveyResult ? surveyResult.score < config.minScore : false
    }

    getMilestoneReviewerName(milestone) {
        let reviewedBy = Locale.getLanguageItem('unknown')

        if (!milestone || !milestone.reviewerId) {
            return reviewedBy
        }

        const reviewer = store.state.user.users[milestone.reviewerId]

        if (!reviewer) {
            Logging.error(`Could not find reviewer ${milestone.reviewerId} for milestone ${milestone.slug}`)

            return reviewedBy
        }

        return store.state.user.users[milestone.reviewerId]?.titledFullName
    }
}

export default new ClinicalMilestoneService()
