import ClinicalMilestoneService from '@serv/ClinicalMilestoneService'
import Milestone from '@model/Milestone'
import moment from 'moment'
import PatientService from '@serv/PatientService'
import ReferralMilestone from '@model/ReferralMilestone'
import store from '@/store'
import SurveyResult from '@model/SurveyResult'
import SurveyResultsService from '@serv/SurveyResultsService'
import Utils from '@serv/Utils'

class TaskService {
    isPatientSurveyResultReviewTask(patient, surveyResult) {
        if (surveyResult.status == SurveyResult.Status.partial || surveyResult.enteredByClinician) {
            return
        }

        if (SurveyResultsService.isPatientSurveyResultReviewed(surveyResult)) {
            return false
        }

        const tasksConfig = store.state.user.owner.keyValues?.dash?.tasks || []

        for (let config of tasksConfig) {
            const fnName = `resolveReviewTaskOfType_${config.type}`

            if (typeof this[fnName] == 'function') {
                if (this[fnName](patient, surveyResult, config)) {
                    return true
                }
            }
        }

        return false
    }

    resolveReviewTaskOfType_surveyNeedsReview(patient, surveyResult, config) {
        const surveySlugs = ClinicalMilestoneService.getContentSlugsFromSpec(config)

        if (surveySlugs && surveySlugs.includes(surveyResult.surveySlug)) {
            if (config.rtmOnly) {
                const journey = store.state.resources.journeys[surveyResult.journeySlug]

                if (!journey.hasRtm && !patient.rtmActive) {
                    return false
                }
            }

            const surveyEndDate = moment(surveyResult.endTime).format(Utils.serialisedDateFormat)

            if (config.startDate && surveyEndDate < config.startDate) {
                return false
            }

            const rtmEpisodeStartDate = patient.activeRtmEpisodeStartDate
                ? moment(patient.activeRtmEpisodeStartDate).format(Utils.serialisedDateFormat)
                : null

            const rtmEpisodeEndDate = patient.activeRtmEpisodeEndDate
                ? moment(patient.activeRtmEpisodeEndDate).format(Utils.serialisedDateFormat)
                : null

            if (patient.rtmActive && (surveyEndDate < rtmEpisodeStartDate || surveyEndDate > rtmEpisodeEndDate)) {
                return false
            }

            if (!patient.rtmActive && config.endMilestoneDate) {
                const patientJourney = store.state.user.patientJourneys[surveyResult.patientJourneyId]
                const taskEndDate = patientJourney.getMilestoneOfSlugDate(config.endMilestoneDate)

                if (taskEndDate && surveyEndDate >= taskEndDate) {
                    return false
                }
            }

            return config.minScore ? surveyResult.score >= config.minScore : true
        }

        return false
    }

    resolveReviewTaskOfType_promScoreDeterioration(patient, surveyResult, config) {
        if (!config.minDecrease) {
            return false
        }

        const survey = store.state.content.surveys[surveyResult.surveySlug]

        if (!survey || !survey.isPromSurvey) {
            return false
        }

        const surveyEndDate = moment(surveyResult.endTime).format(Utils.serialisedDateFormat)

        if (config.startDate && surveyEndDate < config.startDate) {
            return false
        }

        const patientJourney = store.state.user.patientJourneys[surveyResult.patientJourneyId]
        const previousSurvey = SurveyResultsService.getPreviousSurveyResult(patientJourney, surveyResult)

        return (
            previousSurvey &&
            previousSurvey.score &&
            surveyResult.score &&
            previousSurvey.score - surveyResult.score >= config.minDecrease
        )
    }

    isPreassessmentSurveyTask(patient) {
        const owner = store.state.user.owner
        const taskConfig = owner.getTaskByType('preassessmentSurvey')
        const cptConfig = owner.getPatientPageTabCptConfig('GrmaTabCptProcedureDetails')

        if (!taskConfig || !cptConfig || !cptConfig.contentSlug) {
            return false
        }

        const surveyResult = (patient.surveyResults || []).find(
            surveyResult => surveyResult.surveySlug == cptConfig.contentSlug
        )

        if (!surveyResult) {
            return false
        }

        const surveyEndDate = moment(surveyResult.endTime).format(Utils.serialisedDateFormat)

        if (taskConfig.startDate && surveyEndDate < taskConfig.startDate) {
            return false
        }

        const patientJourney = store.state.user.patientJourneys[surveyResult.patientJourneyId]

        return (
            surveyResult.status == SurveyResult.Status.complete &&
            !patientJourney.keyValues.status &&
            !patientJourney.keyValues.mrsa &&
            !patientJourney.keyValues.rag
        )
    }

    isSurveyDownloadPdfTask(patient, surveyResult) {
        const owner = store.state.user.owner
        const taskConfig = owner.getTaskByType('downloadSurveyPdf')

        if (!taskConfig || !taskConfig.contentSlugs || !taskConfig.contentSlugs.includes(surveyResult.surveySlug)) {
            return false
        }

        const surveyEndDate = moment(surveyResult.endTime).format(Utils.serialisedDateFormat)

        if (taskConfig.startDate && surveyEndDate < taskConfig.startDate) {
            return false
        }

        const patientJourney = store.state.user.patientJourneys[surveyResult.patientJourneyId]
        const surveyReviewMilestones = patientJourney.getMilestonesOfType(Milestone.Type.surveyReview) || []
        const surveyDownloadMilestone = surveyReviewMilestones.find(
            milestone => milestone.resultPrmId == surveyResult.resultPrmId && milestone.slug == 'survey-download'
        )

        return !surveyDownloadMilestone
    }

    isSurgicalJourneyTask(patientJourney) {
        const owner = store.state.user.owner
        const taskConfig = owner.getTaskByType('rjahSetSurgicalJourney')

        return (
            !!taskConfig &&
            !!taskConfig.journeySlugs &&
            !!patientJourney &&
            taskConfig.journeySlugs.includes(patientJourney.journeySlug)
        )
    }

    isRtmPeriodReviewTask(patientJourney, milestone) {
        const config = store.state.user.owner.getTaskByType(`rtmPeriodReview${milestone.rtmCode}`)

        if (milestone.reviewerId || !config) {
            return false
        }

        if (config.startDate) {
            return milestone.date >= config.startDate
        }

        if (config && config.rtmOnly) {
            const journey = store.state.resources.journeys[patientJourney.journeySlug]

            return !!journey.hasRtm
        }

        return true
    }

    isRtmWindowReviewTask(patientJourney) {
        const config = store.state.user.owner.getTaskByType('rtmWindowReview')

        if (config && config.rtmOnly) {
            const journey = store.state.resources.journeys[patientJourney.journeySlug]

            return !!journey.hasRtm
        }

        return !!config
    }

    isRtmScheduledReminderReviewTask(patientJourney, scheduleSlug, startMoment) {
        const user = store.state.user
        const taskConfig = user.owner.getTaskByType('rtmReminder')

        if (taskConfig && taskConfig.rtmOnly) {
            const journey = store.state.resources.journeys[patientJourney.journeySlug]

            if (!journey.hasRtm) {
                return false
            }
        }

        const milestoneDate = startMoment.format(Utils.serialisedDateFormat)

        if (taskConfig && taskConfig.startDate && milestoneDate < taskConfig.startDate) {
            return false
        }

        return !!taskConfig && !PatientService.getPatientRtmScheduledReviewMilestone(patientJourney, scheduleSlug)
    }

    isRtmUnregisteredPatientTask(patientJourney, scheduleSlug, startMoment) {
        const user = store.state.user
        const taskConfig = user.owner.getTaskByType('rtmUnregisteredReview')

        const milestoneDate = startMoment.format(Utils.serialisedDateFormat)

        if (!taskConfig || milestoneDate < taskConfig?.startDate) {
            return false
        }

        return !PatientService.getUnregisteredPatientRtmScheduledReviewMilestone(patientJourney, scheduleSlug)
    }

    isRtmUnsharedStepsTask(patientJourney, scheduleSlug, startMoment) {
        const user = store.state.user
        const taskConfig = user.owner.getTaskByType('rtmStepsReview')

        const milestoneDate = startMoment.format(Utils.serialisedDateFormat)

        if (!taskConfig || milestoneDate < taskConfig?.startDate) {
            return false
        }

        return !PatientService.getPatientScheduledReviewMilestone(patientJourney, 'rtm-steps-review', scheduleSlug)
    }

    isRtmInsufficientStepsTask(patientJourney, scheduleSlug, startMoment) {
        const user = store.state.user
        const taskConfig = user.owner.getTaskByType('rtmInsufficientStepsReview')

        const milestoneDate = startMoment.format(Utils.serialisedDateFormat)

        if (!taskConfig || milestoneDate < taskConfig?.startDate) {
            return false
        }

        return !PatientService.getPatientScheduledReviewMilestone(
            patientJourney,
            'rtm-insufficient-steps-review',
            scheduleSlug
        )
    }

    isCustomTaskReviewTask(patientJourney, reviewMilestone) {
        const today = moment().format(Utils.serialisedDateFormat)

        if (reviewMilestone.reviewerId || reviewMilestone.date > today) {
            return false
        }

        const config = store.state.user.owner.getTaskByType('customTask')

        if (config && config.rtmOnly) {
            const journey = store.state.resources.journeys[patientJourney.journeySlug]

            return !!journey.hasRtm
        }

        return !!config
    }

    isReferralTask(patientJourney, milestone) {
        const config = store.state.user.owner.getTaskByType('referral')

        return !!config && PatientService.isReferralNeedingReview(patientJourney, milestone)
    }

    hasReferralTask() {
        return !!store.state.user.owner.getTaskByType('referral')
    }

    hasAppointmentTask() {
        return !!store.state.user.owner.getTaskByType('appointment')
    }

    /**
     * Display the task on the patient page key dates table referral row:
     *     IF I am a dash user
     *     AND it's been >= 48 hours since I've accepted a referral to me
     *     AND no appointment exists, referencing this patient and referral, with me assigned as the “responsible clinician”
     */
    isAppointmentWithReferralReferenceTask(patientJourney, referralMilestone) {
        const user = store.state.user.user

        if (
            !this.hasAppointmentTask() ||
            referralMilestone.status != ReferralMilestone.Status.accepted ||
            referralMilestone.referredToId != user.personaId ||
            referralMilestone.acceptedById != user.personaId ||
            moment().diff(moment(referralMilestone.acceptedDate), 'hours') < 48
        ) {
            return false
        }

        const appointmentMilestone = patientJourney?.milestones.find(
            milestone =>
                milestone.type == Milestone.Type.appointment &&
                milestone.referralId == referralMilestone.id &&
                milestone.clinicianId == user.personaId
        )

        return !appointmentMilestone
    }

    /**
     * Display the task on patient page key dates table "Add key date" button:
     *      IF I am a dash user
     *      AND it's been >= 48 hours since patient pathway was created with me assigned as the primary physio
     *      AND no appointments exists, referencing this patient and no active referrals, with me assigned as the "responsible clinician"
     */
    isAppointmentWithoutReferralTask(patientJourney) {
        const dischargeMilestone = patientJourney.getMilestoneOfSlug('discharge')

        if (!this.hasAppointmentTask() || dischargeMilestone) {
            return false
        }

        const now = moment()
        const user = store.state.user.user

        const hoursSincePathwayCreated = patientJourney.createdTime
            ? now.diff(moment(patientJourney.createdTime), 'hours')
            : 0

        if (patientJourney.leadId != user.personaId || hoursSincePathwayCreated < 48) {
            return false
        }

        const activeReferralStatuses = [ReferralMilestone.Status.offered, ReferralMilestone.Status.accepted]
        const referralMilestones = patientJourney
            .getMilestonesOfSlug('referral')
            .filter(milestone => activeReferralStatuses.includes(milestone.status))

        if (referralMilestones.length > 0) {
            return false
        }

        const appointmentMilestones = patientJourney
            .getMilestonesOfSlug('appointment')
            .filter(
                milestone =>
                    milestone.date >= now.format(Utils.serialisedDateFormat) && milestone.clinicianId == user.personaId
            )

        return appointmentMilestones.length == 0
    }

    displaySurveyResultScoreForTask(surveyResult) {
        const owner = store.state.user.owner
        const tasks = owner.getTasksByType('surveyNeedsReview') || []

        for (const task of tasks) {
            const surveySlugs = ClinicalMilestoneService.getContentSlugsFromSpec(task)

            if (surveySlugs && surveySlugs.includes(surveyResult.surveySlug) && task.displayScore) {
                return true
            }
        }

        return false
    }

    getAppointmentNumTasks(patientJourney) {
        let tasksCount = 0
        const referralMilestones = patientJourney.getMilestonesOfSlug('referral')

        referralMilestones.forEach(milestone => {
            if (this.isAppointmentWithReferralReferenceTask(patientJourney, milestone)) {
                tasksCount++
            }
        })

        if (this.isAppointmentWithoutReferralTask(patientJourney)) {
            tasksCount++
        }

        return tasksCount
    }

    getReferralNumTasks(patientJourney) {
        let tasksCount = 0

        const referralMilestones = patientJourney.getMilestonesOfSlug('referral')

        referralMilestones.forEach(milestone => {
            if (this.isReferralTask(patientJourney, milestone)) {
                tasksCount++
            }
        })

        return tasksCount
    }

    getCustomTasksCount(patientJourney) {
        let tasksCount = 0

        const customTaskMilestones = patientJourney.getMilestonesOfSlug('custom-task')

        customTaskMilestones.forEach(milestone => {
            if (this.isCustomTaskReviewTask(patientJourney, milestone)) {
                tasksCount++
            }
        })

        return tasksCount
    }
}

export default new TaskService()
