import _ from 'lodash'
import { capitalize } from '@comp/Filters'
import ConfigManager from '@config/ConfigManager'
import FeatureFlagService from '@serv/FeatureFlagService'
import Locale from '@serv/Locale'
import Logging from '@serv/Logging'
import moment from 'moment'
import ReportService from '@serv/ReportService'
import Request from '@serv/Request'
import Storage from '@serv/Storage'
import store from '@src/store/index'
import User from '@model/User'

/**
 * Helpers for getting, processing and storing all resources on first login/refresh.
 */
class ResourceService {
    // For tests
    constructor() {
        this.clearValidationLog()

        // Any report slug containing any of these will be requested with POST (with empty payload) not GET
        this.postedReportSlugPatterns = ['department-list']
    }

    clearValidationLog() {
        this.validationLog = ''
    }

    // Returns true if the report should be POSted.
    isReportSlugPosted(slug) {
        return this.postedReportSlugPatterns.some(pattern => slug.includes(pattern))
    }

    // From a report URL stem, replace the {reportSlug} param, and perform any tidy-up.
    getUrlWithReportSlug(stem, reportSlug) {
        let requestUrl = stem.replace('{reportSlug}', reportSlug)
        if (requestUrl.includes('/?') && requestUrl.endsWith('/')) {
            requestUrl = requestUrl.slice(0, -1)
        }

        return requestUrl
    }

    // Request all the resources, including from the reports API.
    requestAll(user) {
        // Resources
        const resourceNames = this.getResourceNames(user)
        // Reports
        const reportSlugMap = ReportService.getReportSlugMap(user) // report key to slug
        const reportsMap = {} // report slug to URL
        Object.keys(reportSlugMap).forEach(reportKey => {
            const reportSlug = reportSlugMap[reportKey]
            const isPosted = this.isReportSlugPosted(reportSlug)
            const stem = isPosted ? Request.Stem.reportsFiltered : Request.Stem.reports
            let requestUrl = this.getUrlWithReportSlug(stem, reportSlug)

            if (user.isClinician) {
                switch (reportKey) {
                    case 'prom':
                        requestUrl += '?filter=includeInactive'
                        break
                    // This is done to avoid having default filter added to request
                    case 'keyValue':
                        break
                    case 'patients':
                        if (Storage.get('spoof')) {
                            requestUrl += '?spoof=true'
                        }
                        break
                    default:
                        if (user.has(User.Capability.canLeadTeam)) {
                            requestUrl += '?filter=myPatients'
                        }
                        break
                }
            }
            reportsMap[reportSlug] = requestUrl
        })

        const promise = this.makePromiseForAllResourcesAndReports(resourceNames, reportsMap)

        return promise
    }

    // Return the resource names to load for the specified user.
    getResourceNames(user) {
        let resources = ['content', 'journeys', 'providers']
        if (user?.isAdmin) {
            return []
        }
        if (user.isPatient) {
            if (store.state.portalRegistration.isWebRegistration) {
                // Patient registration
                return []
            }

            // Patient web portal
            return [...resources, 'teams', 'teamMembers']
        }

        if (user.isOutcomesExec) {
            resources = [...resources, 'surgeons', 'teams']
        } else if (user.isExec) {
            return [...resources, 'surgeons', 'teams']
        } else {
            resources = [...resources, 'teams', 'teamMembers']
        }
        // Only request 'patients' if we have old-syle PatientListPage
        const owner = store.state.user.owner
        if (!owner.hasFeatureFlag('hasPatientJourneyList')) {
            resources = [...resources, 'patients']
        }

        return resources
    }

    // Return a Promise for all "original load" resource and report requests.
    makePromiseForAllResourcesAndReports(resourceNames, reportsMap = {}) {
        const requestsTotal = resourceNames.length + Object.keys(reportsMap).length
        let responsesTotal = 0
        let progress = 0

        // Functions to handle updating of download progress, based on request responses and timer
        function _updateDownloadProgress() {
            responsesTotal++
            progress = (100 * responsesTotal) / requestsTotal
            store.commit('setDownloadProgress', Math.round(progress))
            _clearProgressTimer()
            if (responsesTotal < requestsTotal) {
                _initProgressTimer()
            }
        }
        function _onProgressTimer() {
            // Each second, edge towards the next response value
            const nextProgress = (100 * (responsesTotal + 1)) / requestsTotal
            progress += (nextProgress - progress) / 4
            store.commit('setDownloadProgress', Math.round(progress))
        }
        let downloadProgressTimer
        function _initProgressTimer() {
            downloadProgressTimer = window
                ? window.setTimeout(() => {
                      _onProgressTimer()
                      _initProgressTimer()
                  }, 1000)
                : undefined
        }
        function _clearProgressTimer() {
            if (window && downloadProgressTimer) {
                window.clearTimeout(downloadProgressTimer)
            }
        }

        function _handleRequestError(resourceName, url) {
            const owner = store.state.user.owner
            const baseUrl = Array.isArray(url) ? url[0] : url

            Logging.error(
                `Error response from ${baseUrl.split('?')[0]}, trying to load resource '${resourceName}' for owner '${owner.slug}'`
            )

            return {
                error: {
                    data: [],
                    message: `Error response from: ${baseUrl} ... setter for ${resourceName} will not be called.`
                }
            }
        }

        function _handleRequestSuccess(res, resourceName) {
            return { [resourceName]: res }
        }

        const resourcePromises = resourceNames.map(resourceName => {
            // Get Stem and make any replacements
            let url = Request.Stem[resourceName]
            url = url.replace('{locale}', Locale.getActiveLocale())
            const fnName = `_updateUrlForResource_${resourceName}`
            if (this[fnName]) {
                url = this[fnName](url)
            }

            if (Array.isArray(url)) {
                const promises = url.map(currentUrl => Request.get(currentUrl))

                return Promise.all(promises)
                    .then(responses => {
                        const responseArray = responses.flatMap(res => res.data)
                        const uniqueResponseArray = _.uniqBy(responseArray, 'slug')

                        return _handleRequestSuccess(uniqueResponseArray, resourceName)
                    })
                    .catch(() => _handleRequestError(resourceName, url))
                    .finally(() => _updateDownloadProgress())
            }

            return Request.get(url)
                .then(res => _handleRequestSuccess(res.data, resourceName))
                .catch(() => _handleRequestError(resourceName, url))
                .finally(() => _updateDownloadProgress())
        })
        const reportPromises = Object.keys(reportsMap).map(reportSlug => {
            const reportUrl = reportsMap[reportSlug]
            const isPosted = this.isReportSlugPosted(reportSlug)
            const promise = isPosted ? Request.post(reportUrl, {}) : Request.get(reportUrl)

            return promise
                .then(res => {
                    _updateDownloadProgress()

                    return res
                })
                .catch(() => {
                    _updateDownloadProgress()

                    return {
                        error: {
                            data: [],
                            message: `Error response from: ${reportUrl} ... associated report dataset will be empty.`
                        }
                    }
                })
        })
        const featureFlagPromise = FeatureFlagService.fetchFeatureFlags()
        const promises = [...resourcePromises, ...reportPromises, featureFlagPromise]

        return Promise.all(promises).then(results => {
            results = results.filter(item => item !== undefined)
            // Verbose request logging from now
            // Request.isLoggingVerbose = true
            this.setResources(results)
            _clearProgressTimer()
        })
    }

    // Resource-specific URL modifications
    _updateUrlForResource_patients(url) {
        url += '?filter=includeInactive'
        if (Storage.get('spoof')) {
            url += '&spoof=true'
        }

        return url
    }
    _updateUrlForResource_providers(url) {
        const adminDatesTabCpt = 'TabCptAdminDates'
        const owner = store.state.user.owner

        if (owner.getPatientPageTabCptConfig(adminDatesTabCpt)) {
            return `${url}?includeFromComponent=${adminDatesTabCpt}`
        }

        return url
    }

    // Store the returned resources in Vuex, and ensure they are all validated and post-processed.
    setResources(results) {
        const reports = {}
        results.forEach(result => {
            if (result.data) {
                // Report result
                const url = (result.config || {}).url
                let type
                if (url.includes('department-list')) {
                    type = 'departmentList'
                } else if (url.includes('clinician-list')) {
                    type = 'clinicianList'
                } else {
                    type = result.data.type || 'kpi' // TODO KPIS: FIX
                }
                reports[type] = result.data
            } else {
                // Resource result
                const key = Object.keys(result)[0]
                let dataset = result[key]
                const functionName = `set${capitalize(key)}`

                // Special cases: resource validation
                if (key == 'patientJourneys') {
                    dataset = this.validatePatientJourneys(dataset)
                }

                // Commit single resource (e.g. content, journeys)
                store.commit(functionName, dataset)
            }
        })
        // Make code more robust against failed resource responses - ensure store.state elements are empty
        // object/array, rather than undefined
        store.state.resources.departments = store.state.resources.departments || {}
        store.state.resources.journeys = store.state.resources.journeys || {}
        store.state.resources.practiceSurveyResults = store.state.resources.practiceSurveyResults || {}
        store.state.resources.providers = store.state.resources.providers || {}
        store.state.resources.reports = store.state.resources.reports || {}

        // Validate all reports - this may filter the datasets, and log warnings.
        this.validateReports(reports)

        // Commit all reports
        store.commit('setReports', reports)

        // Post-process
        store.commit('resolveContent')
        store.commit('resolveResources')
        store.commit('resolveReports')
        store.commit('resolveUsers')

        const user = store.state.user.user
        // Unclear why if (user) is required, added to fix https://myrecovery.sentry.io/issues/4059016531/events/a64f2f450888465197af2969ad98b672/
        if (user) {
            if (user.isExec) {
                // NOTE: If user.isMock, the function below creates the mock data
                store.commit('resolveClinicians')
                if (user.isProviderExec) {
                    store.commit('resolveDepartments')
                }
            }
            if (user.has(User.Capability.canViewAllTeamPatients) || user.has(User.Capability.canViewPatientData)) {
                store.commit('resolvePatients')
            }
        }
    }

    // Ensure that any stored registration milestone is no later than the earliest survey result date.
    postProcessPatient(patient) {
        const regMilestone = patient.globalJourney.getMilestoneOfSlug('registration')
        if (regMilestone) {
            const earliestDate = patient.getEarliestSurveyResultDate()
            if (earliestDate && earliestDate < regMilestone.date) {
                Logging.info(`Updating reg milestone date from ${regMilestone.date} to ${earliestDate}`)
                regMilestone.date = earliestDate
            }
        }
    }

    // Validate all reports.
    validateReports(reports) {
        const user = store.state.user.user
        if (user?.isPatient) {
            return
        }
        if (reports.prom) {
            reports.prom.dataset = this.validatePracticeSurveyResults(reports.prom.dataset)
        }
        if (reports.prem) {
            reports.prem.dataset = this.validatePracticePremResults(reports.prem.dataset)
        }
        if (reports.progressTracker) {
            reports.progressTracker.dataset = this.validatePracticeProgressTrackerResults(
                reports.progressTracker.dataset
            )
        }
        if (reports.procedureStats) {
            reports.procedureStats.dataset = this.validatePracticeProcedureStatsResults(reports.procedureStats.dataset)
        }
    }

    // Validation helper: check patientIds map to our stored Patient objects.
    validateDatasetPatientIds(dataset, reportName) {
        const objs = dataset.filter(obj => store.state.user.patients[obj.patientId] == undefined)
        if (objs.length > 0) {
            const grouped = _.groupBy(objs, 'patientId')
            this.validationLog += `${reportName}: has ${
                objs.length
            } rows where patientId is not in user.patients. Missing objects: ${Object.keys(grouped)}\n`
            dataset = dataset.filter(obj => store.state.user.patients[obj.patientId])
        }

        return dataset
    }

    // Validation helper: check surveySlugs map to our stored Survey objects.
    validateDatasetSurveySlugs(dataset, reportName) {
        const objs = dataset.filter(obj => store.state.content.surveys[obj.surveySlug] == undefined)
        if (objs.length > 0) {
            const grouped = _.groupBy(objs, 'surveySlug')
            this.validationLog += `${reportName}: has ${
                objs.length
            } rows where surveySlug is not in content.surveys. Missing objects: ${Object.keys(grouped)}\n`
            dataset = dataset.filter(obj => store.state.content.surveys[obj.surveySlug])
        }

        return dataset
    }

    /**
     * Validation helper: check stepSlugs map to our stored StepSlug objects.
     * Also called after loading patient page step results.
     */
    validateDatasetStepSlugs(dataset, reportName) {
        // Decide if key is stepSlug or questionSlug
        if (_.isEmpty(dataset)) {
            return dataset
        }
        const keys = ['contentSlug', 'questionSlug', 'stepSlug']
        let key
        for (key of keys) {
            if (dataset[0].hasOwnProperty(key)) {
                break
            }
        }
        const objs = dataset.filter(obj => store.state.content.content[obj[key]] == undefined)
        if (objs.length > 0) {
            const grouped = _.groupBy(objs, key)
            this.validationLog += `${reportName}: has ${
                objs.length
            } rows where ${key} is not in content.content. Missing objects: ${Object.keys(grouped)}\n`
            dataset = dataset.filter(obj => store.state.content.content[obj[key]])
        }

        return dataset
    }

    getExtraSurveysSlugs(surveyResults) {
        let slugsToRequest = []

        surveyResults.forEach(result => {
            const version = result.version || 1
            const existingSurveyContent = store.state.content.content[result.surveySlug]

            // request different version content if do not have it.
            if (
                !existingSurveyContent ||
                (existingSurveyContent.version != version &&
                    !existingSurveyContent.versions.find(survey => survey.version == version))
            ) {
                slugsToRequest.push(`${result.surveySlug}.${version}.${result.locale || 'en'}`)
            }
        })

        return slugsToRequest
    }

    loadExtraSurveyContent(slugsToRequest) {
        return slugsToRequest?.length
            ? Request.get(Request.Stem.contentBySlugVersion, {
                  params: {
                      slugs: _.uniq(slugsToRequest).join(',')
                  }
              })
            : Promise.resolve({})
    }

    // Validation helper: check journeySlugs map to our stored Journey objects.
    validateDatasetJourneySlugs(dataset, reportName) {
        // Find any cases where journeySlug is defined but not found in store
        const objs = dataset.filter(obj => obj.journeySlug && !store.state.resources.journeys[obj.journeySlug])
        if (objs.length > 0) {
            const grouped = _.groupBy(objs, 'journeySlug')
            this.validationLog += `${reportName}: has ${
                objs.length
            } rows where journeySlug is not in resources.journeys. Missing objects: ${Object.keys(grouped)}\n`
            dataset = dataset.filter(obj => store.state.resources.journeys[obj.journeySlug])
        }

        return dataset
    }

    // Validate the practice-survey-results-report dataset.
    validatePracticeSurveyResults(dataset) {
        const reportName = 'practice-survey-results-report'
        const user = store.state.user.user
        this.clearValidationLog()
        if (!_.isEmpty(store.state.user.patients)) {
            dataset = this.validateDatasetPatientIds(dataset, reportName)
        }

        // Debugging: filter response to single patient
        if (ConfigManager.singlePatientId) {
            dataset = dataset.filter(result => result.patientId == ConfigManager.singlePatientId)
            Logging.log(
                `Filtered ${reportName} response to ${dataset.length} rows for single patient ${ConfigManager.singlePatientId}`
            )
            const patientJourneyStepResults = _.groupBy(dataset, 'patientJourneyId')
            Object.keys(patientJourneyStepResults).forEach(patientJourneyId => {
                const results = patientJourneyStepResults[patientJourneyId]
                const surveySlugResults = _.groupBy(results, 'surveySlug')
                Logging.log(
                    `Found ${results.length} SurveyResults for patientJourney ${patientJourneyId}: ${JSON.stringify(
                        Object.keys(surveySlugResults),
                        0,
                        null
                    )}`
                )
            })
        }

        // dataset = this.validateDatasetSurgeonUids(dataset, reportName)
        dataset = this.validateDatasetSurveySlugs(dataset, reportName)
        if (!user.isProducerExec) {
            dataset = this.validateDatasetJourneySlugs(dataset, reportName)
        }

        // Debugging: filter response to single survey
        if (ConfigManager.singleSurveySlug) {
            dataset = dataset.filter(result => result.surveySlug == ConfigManager.singleSurveySlug)
            Logging.log(
                `Filtered ${reportName} response to ${dataset.length} rows for single survey ${ConfigManager.singleSurveySlug}`
            )
            // Sort and log
            dataset.sort((a, b) => {
                return moment(a.endTime).valueOf() - moment(b.endTime).valueOf()
            })
            dataset.forEach(row => {
                Logging.log(`${row.endTime}, ${row.primaryMilestoneDate}, ${row.scheduleSlug}`)
            })
        }

        if (this.validationLog.length > 0) {
            Logging.warn(this.validationLog)
        }
        dataset = dataset.filter(row => {
            const survey = store.state.content.surveys[row.surveySlug]

            return survey && !survey.isChecklistSurvey
        })

        return dataset
    }

    // Validate the practice-prem-report dataset.
    validatePracticePremResults(dataset) {
        const reportName = 'practice-prem-report'
        const user = store.state.user.user
        this.clearValidationLog()
        dataset = this.validateDatasetSurveySlugs(dataset, reportName)
        dataset = this.validateDatasetStepSlugs(dataset, reportName)
        if (!user.isProducerExec) {
            dataset = this.validateDatasetJourneySlugs(dataset, reportName)
        }
        if (this.validationLog.length > 0) {
            Logging.warn(this.validationLog)
        }

        return dataset
    }

    // Validate the practice-progress-tracker dataset.
    validatePracticeProgressTrackerResults(dataset) {
        // const reportName = 'practice-progress-tracker'
        this.clearValidationLog()
        // TODO could check promDisplayPeriod is a valid scheduleSlug ?
        // dataset = this.validateDatasetJourneySlugs(dataset, reportName)
        if (this.validationLog.length > 0) {
            Logging.warn(this.validationLog)
        }

        return dataset
    }

    // Validate the practice-procedure-summary dataset.
    validatePracticeProcedureStatsResults(dataset) {
        // const reportName = 'practice-procedure-stats'
        this.clearValidationLog()
        // TODO validate journeySlug once report fields are sanitised
        // dataset = this.validateDatasetJourneySlugs(dataset, reportName)
        if (this.validationLog.length > 0) {
            Logging.warn(this.validationLog)
        }

        return dataset
    }

    // Validate the resources dataset for 'patientJourneys'.
    validatePatientJourneys(dataset) {
        const reportName = 'patientJourneys resource'
        const user = store.state.user.user
        this.clearValidationLog()
        if (!user.isProducerExec) {
            dataset = this.validateDatasetJourneySlugs(dataset, reportName)
        }
        if (this.validationLog.length > 0) {
            Logging.warn(this.validationLog)
        }

        return dataset
    }

    /**
     * Given a row/object that may contain procedureLabel or procedureCode, return a 'procedureTitle' string value.
     * If instead we have journeySlug, use that to look up procedureCode.
     */
    getRowProcedureTitle(row) {
        if (row.procedureLabel && row.procedureLabel != 'n/a') {
            const stringId = Locale.getStringIdFromSlug(row.procedureLabel, 'procedureLabel')

            return Locale.getLanguageItem(stringId)
        }
        if (row.procedureCode) {
            return row.procedureCode
        }
        const journey = store.state.resources.journeys[row.journeySlug]
        if (journey) {
            return journey.procedureCode
        }
        Logging.log(`Error, could not find Journey: ${row.journeySlug}`)
    }

    /**
     * Get all possible procedure titles (sorted alphabetically).
     *
     * In the default case, the size of this is journeys * procedureLabels, the latter being defined on each journey.
     * In this case each value is the procedureTitle, being the string version of any procedureLabel, or the journey
     * procedureCode for the null procedureLabel.
     *
     * If config is passed in and config.filterExcludeProcedureLabels==true, then instead we list only the journeys,
     * i.e. the procedureCodes (which are readable strings).
     */
    getProcedureTitles(config) {
        config = config || {}
        const journeys = Object.values(store.state.resources.journeys)
        const titles = new Set()
        journeys.forEach(journey => {
            if (config.filterExcludeProcedureLabels || config.qualifier == 'onlyJourneys') {
                titles.add(
                    this.getRowProcedureTitle({
                        procedureCode: journey.procedureCode
                    })
                )
            } else {
                const labels = _.clone(journey.procedureLabels)
                labels.push(undefined) // journey with no procedureLabel
                labels.forEach(procedureLabel => {
                    const title = this.getRowProcedureTitle({
                        procedureCode: journey.procedureCode,
                        procedureLabel: procedureLabel
                    })
                    titles.add(title)
                })
            }
        })

        return [...titles].filter(t => t).sort()
    }

    // Get the (procedureCode, procedureLabel) for a defined procedureLabel string.
    getProcedureCodeLabelPairsForProcedureLabelString(labelString) {
        const pairs = []
        const journeys = Object.values(store.state.resources.journeys)
        for (let i = 0; i < journeys.length; i++) {
            const journey = journeys[i]
            for (const procedureLabel of journey.procedureLabels) {
                const title = this.getRowProcedureTitle({
                    procedureLabel: procedureLabel
                })
                if (title == labelString) {
                    pairs.push({
                        procedureCode: journey.procedureCode,
                        procedureLabel: procedureLabel
                    })
                }
            }
        }

        return pairs
    }

    getJourneysProcedureTitlesAndLabelPairs(procedureTitles, includeProcedureLabels) {
        const pairs = new Set()
        const journeys = Object.values(store.state.resources.journeys)
        const procedureCodes = _.uniq(journeys.map(journey => journey.procedureCode))

        // We use pipe-delimited strings {procedureTitle|procedureCode} to represent procedureTitles, so we can
        // create a set unique by the pair of values. We then split this when returning
        procedureTitles.forEach(procedureTitle => {
            // Each procedureTitle is either some journey.procedureCode, or within some journey.procedureLabels
            if (procedureCodes.includes(procedureTitle)) {
                // procedureTitle IS procedureCode
                pairs.add(`${procedureTitle}|undefined`)

                if (includeProcedureLabels) {
                    // Also add pairs for ALL procedureLabels
                    const filteredJourneys = journeys.filter(journey => journey.procedureCode == procedureTitle)

                    filteredJourneys.forEach(journey => {
                        journey.procedureLabels.forEach(procedureLabel => {
                            pairs.add(`${procedureTitle}|${procedureLabel}`)
                        })
                    })
                }
            } else {
                const resultPairs = this.getProcedureCodeLabelPairsForProcedureLabelString(procedureTitle)
                resultPairs.forEach(pair => pairs.add(`${pair.procedureCode}|${pair.procedureLabel}`))
            }
        })

        return [...pairs].map(str => str.split('|').map(element => (element == 'undefined' ? undefined : element)))
    }

    // Get all Providers matching the specified tag.
    getProvidersWithTagDimensionValue(dimension, value) {
        const providers = Object.values(store.state.resources.providers)
        const matches = providers.filter(provider => provider.tags[dimension] == value)

        return matches
    }

    /**
     * Remap array journeySlug values to associated 'standard' journey slugs.
     * NOTE: Created 09/08/21 as a temporary solution to creating a standard-centric view for ProducerExecs.
     */
    get ownerJourneySlugSuffixToStandard() {
        return {
            corin: {
                thr: 'corin-thr',
                thr_ops: 'corin-thr_ops',
                thrbi: 'corin-thrbi',
                thrbi_ops: 'corin-thrbi_ops',
                tkr: 'corin-tkr',
                tkr_omni: 'corin-tkr_omni',
                tkrbi: 'corin-tkrbi',
                tkrbi_omni: 'corin-tkrbi_omni'
            },
            stry_aus: {
                '-pka': 'stry_aus-pka',
                '-pkamako': 'stry_aus-pkamako',
                '-tha': 'stry_aus-tha',
                '-thamako': 'stry_aus-thamako',
                '-tka': 'stry_aus-tka',
                '-tkamako': 'stry_aus-tkamako'
            }
        }
    }
    remapRowsJourneySlugsForOwner(rows, owner) {
        const mapping = this.ownerJourneySlugSuffixToStandard[owner.slug]
        if (mapping) {
            rows.forEach(row => {
                if (row.journeySlug) {
                    for (const suffix of Object.keys(mapping)) {
                        if (row.journeySlug.endsWith(suffix)) {
                            row.journeySlug = mapping[suffix]
                            break
                        }
                    }
                }
            })
        }
    }
    remapJourneySlugsForOwner(journeySlugs, owner) {
        const mapping = this.ownerJourneySlugSuffixToStandard[owner.slug]
        if (mapping) {
            return journeySlugs.map(journeySlug => {
                for (const suffix of Object.keys(mapping)) {
                    if (journeySlug.endsWith(suffix)) {
                        return mapping[suffix]
                    }
                }

                return journeySlug
            })
        }
    }

    /**
     * Remap reports journeySlug values to associated 'standard' journey slugs.
     * NOTE: Created 09/08/21 as a temporary solution to creating a standard-centric view for ProducerExecs.
     */
    remapReportsJourneySlugs() {
        const owner = store.state.user.owner
        const reportTypes = ['prom', 'prem', 'procedureStats']
        reportTypes.forEach(type => {
            const report = store.state.resources.reports[type]
            if (report) {
                this.remapRowsJourneySlugsForOwner(report.dataset, owner)
            }
        })
    }

    // Given a report, calculate a procedureTitle property on each row.
    addProcedureTitleToRows(rows) {
        for (const row of rows) {
            row.procedureTitle = this.getRowProcedureTitle(row)
        }
    }

    // Filter rows to match clinician journeys (for mocking code only).
    filterRowsByClinicianProcedureTitles(rows, clinician) {
        const clinicianProcedureCodes = clinician.journeySlugs.map(
            journeySlug => store.state.resources.journeys[journeySlug].procedureCode
        )

        return rows.filter(row => clinicianProcedureCodes.includes(row.procedureTitle))
    }

    /**
     * Given a report, rewrite the report to be grouped by procedureTitle.
     * We group by all properties of the row object:
     * - EXCEPT journeySlug, procedureCode, <property>
     * - AND procedureTitle
     * We then recalculate <property> by doing a reduce.
     */
    regroupRowsByProcedureTitle(rows, property) {
        if (rows.length == 0) {
            return rows
        }
        // Calculate groupBy string
        const ignoreProperties = ['journeySlug', 'procedureCode', 'procedureLabel']
        const groupByKeys = Object.keys(rows[0]).filter(key => key != property && !ignoreProperties.includes(key))
        const grouped = _.groupBy(rows, row => {
            const tuple = groupByKeys.reduce((total, key) => total + row[key], '')

            return tuple
        })
        // Rewrite report
        rows = []
        Object.keys(grouped).forEach(key => {
            const results = grouped[key]
            const total = results.reduce((total, result) => total + result[property], 0)
            // Create a single row with this total
            const merged = _.clone(results[0])
            merged[property] = total
            ignoreProperties.forEach(property => delete merged[property])
            rows.push(merged)
        })

        return rows
    }

    // Add procedureTitle property to certain report rows, and rewrite certain reports grouped by this property.
    regroupReportsByProcedureTitle() {
        if (!store.state.resources.reports) {
            return
        }
        // For mock users, the prom dataset here will be empty.
        // Instead ClinicianMockService calls ResourceService.addProcedureTitleToRows directly on the store.state.resources.practiceSurveyResults
        const reportTypesToAddProcedureTitle = ['prom', 'prem', 'procedureStats']
        const _this = this
        reportTypesToAddProcedureTitle.forEach(type => {
            const report = store.state.resources.reports[type]
            if (report) {
                _this.addProcedureTitleToRows(report.dataset)
            }
        })
        const reportTypesToRegroupProperty = {
            prem: 'total',
            procedureStats: 'performed'
        }
        Object.keys(reportTypesToRegroupProperty).forEach(type => {
            const report = store.state.resources.reports[type]
            if (report) {
                report.dataset = _this.regroupRowsByProcedureTitle(report.dataset, reportTypesToRegroupProperty[type])
            }
        })
    }

    /**
     * Given an array of objects, aggregate 'value' and 'numPatients' properties across objects
     * which share the same values of the specified keys.
     */
    aggregateRowsByMatchingKeyValues(rows, keys) {
        const keyObjects = {}

        // Build the map of queryParams index to array of objects
        rows.forEach(row => {
            const rowKeyValues = {}
            keys.forEach(key => (rowKeyValues[key] = row[key]))
            const rowKey = Request.getObjectAsQueryParams(rowKeyValues)
            keyObjects[rowKey] = keyObjects[rowKey] || []
            keyObjects[rowKey].push(row)
        })
        // Build a new array where each object aggregates 'value' and 'numPatients' over the objects
        rows = []
        Object.keys(keyObjects).forEach(key => {
            const objects = keyObjects[key]
            const object = _.clone(objects[0])
            const multipliedValue = objects.reduce((total, row) => total + row.value * row.numPatients, 0)
            object.numPatients = objects.reduce((total, row) => total + row.numPatients, 0)
            object.value = multipliedValue / object.numPatients
            object.score = object.value
            rows.push(object)
        })

        return rows
    }

    /**
     * Get all providers in the store.
     * If includeLegacy, we include even those with Provider.isLegacy==true, else we filter these out.
     */
    getProviders(includeLegacy) {
        const allProviders = Object.values(store.state.resources.providers || {})
        const providers = allProviders.filter(provider => !provider.isLegacy || !includeLegacy)

        return providers
    }

    /**
     * When populating a patient list filter, get all suitable providers:
     *  - for hcpCareNavigator, all providers in the store
     *  - for clinician users, all providers from all user's teams
     *  - for other users, all providers from ALL teams
     */
    getPatientListProviders() {
        const providers = store.state.resources.providers
        let teams = Object.values(store.state.user.teams)
        const user = store.state.user.user
        let result
        switch (user.role) {
            case User.Role.hcpCareNavigator:
                result = Object.values(providers)
                break

            default:
                if (user.isClinician) {
                    teams = teams.filter(team => team.hasClinician(user) && !team.isLegacy)
                }
                result = _.uniq(teams.map(team => providers[team.providerSlug]))
                break
        }
        result = result.filter(p => p && !p.isLegacy) // removed undefined, sanitise legacy data

        return result
    }

    /**
     * When making a selection for a patient team, get all suitable providers for a clinician.
     * IF clinician is defined: all providers they are a member of (or lead of)
     * ELSE:
     *  - for all Clinicians, all providers from teams the dash user is a member of
     *  - for ProviderExec (and others), all providers the dash user has visibility of
     */
    getTeamDetailsProvidersForClinician(clinician) {
        const providers = store.state.resources.providers
        const user = store.state.user.user
        let result
        if (!clinician) {
            switch (user.persona) {
                case User.Persona.clinician:
                    // Providers from dash user's teams
                    result = user.providerSlugs.map(slug => providers[slug])
                    break

                default:
                    // All providers
                    result = Object.values(providers)
                    break
            }
        } else if (!clinician.providerSlugs) {
            Logging.warn('No lead providers found')

            return []
        } else {
            // Clinician
            result = clinician.providerSlugs.map(slug => providers[slug])
        }
        result = result.filter(p => p && !p.isLegacy) // removed undefined, sanitise legacy data

        return result.sort((a, b) => a.title.localeCompare(b.title))
    }

    // Returns true if a provider is referenced in the TabCptAdminDates mapping, i.e. is a "facility provider".
    isProviderFacility(providerSlug) {
        const owner = store.state.user.owner
        const keyDatesConfig = owner.getPatientPageTabCptConfig('TabCptAdminDates')
        if (keyDatesConfig) {
            const periodTypeToProviderSlugs = keyDatesConfig.periodTypeToProviderSlugs
            if (periodTypeToProviderSlugs) {
                for (const slugs of Object.values(periodTypeToProviderSlugs)) {
                    if (slugs.includes(providerSlug)) {
                        return true
                    }
                }
            }
        }

        return false
    }

    getProvidersByAttribute(attribute) {
        if (!attribute) {
            Logging.warn('No attribute specified, returning no users')

            return []
        }

        let providers = Object.values(store.state.user.users).filter(user => user[attribute])
        providers = _.orderBy(providers, ['lastName', 'firstName'])

        return providers.map(provider => ({ title: provider.titledFullName, value: provider.personaId }))
    }

    /**
     * Get all "RTM providers", i.e. clinicians that canReviewRtm.
     * NOTE: An "RTM provider" is a Clinician, not a Provider!
     */
    getRtmProviders() {
        return this.getProvidersByAttribute('isRtmProvider')
    }

    getPtProviders() {
        return this.getProvidersByAttribute('isHcpPt')
    }

    getCareNavigatorProviders() {
        return this.getProvidersByAttribute('isHcpCareNavigator')
    }

    getTeamMembersByProviderSlug(providerSlug) {
        const teams = Object.values(store.state.user.teams).filter(
            team => !team.isLegacy && team.providerSlug == providerSlug
        )
        const memberIds = teams.reduce((memberIds, team) => {
            const validMemberIds = team.memberIds.filter(personaId => !!store.state.user.users[personaId])

            return [team.leadId, ...validMemberIds, ...memberIds]
        }, [])

        let teamMembers = _.uniq(memberIds)
            .map(personaId => store.state.user.users[personaId])
            .filter(user => user.has(User.Capability.canViewPatientData))

        return _.orderBy(teamMembers, ['lastName', 'firstName'])
    }

    requestContentBySlugVersion(slugs, version, locale) {
        slugs = _.uniq(slugs)

        return Request.get(Request.Stem.contentBySlugVersion, {
            params: {
                slugs: slugs
                    .map(slug => {
                        let string = `${slug}.${version || 1}`
                        if (locale) {
                            string += `.${locale}`
                        }

                        return string
                    })
                    .join(',')
            }
        })
    }
}

export default new ResourceService()
